import FullScreenLayout from "../../Layouts/FullScreenLayout";
import {useNavigate, useParams} from "react-router-dom";
import {gql, useMutation} from "@apollo/client";
import React, {useEffect, useState} from "react";
import routes from "../../Router/routes";
import {CenterScreenModal} from "../../Components/Modals/CenterScreenModal";
import SignupWindow from "../../Components/Signup/SignupWindow";

const VERIFY_MUTATION = gql`
    mutation VerifyUser($email: String!, $verificationToken: String!) {
        verifyUser(input: {
            email: $email
            verificationToken: $verificationToken
        }) {
            email
            verificationStatus
        }
    }
`


//@todo: error messages
//@todo: loading icon
const UserVerifyScreen = () => {
    const navigate = useNavigate()
    const { verifyToken, email } = useParams()
    const [ verify, { data: verifyData }] = useMutation(VERIFY_MUTATION)
    const [ loading, setLoading ] = useState(true)

    useEffect(() => {
        try {
            verify({
                variables: {
                    email,
                    verificationToken: verifyToken
                }
            })
        } catch(err) {
            console.error(err)
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        setLoading(false)
        if(verifyData?.verifyUser?.verificationStatus === "VERIFIED") {
            navigate(routes.signin)
        }
    }, [verifyData])

    return (
        <FullScreenLayout>
            <CenterScreenModal>
                <SignupWindow>
                    <>
                        {loading && "Loading..."}
                    </>
                </SignupWindow>
            </CenterScreenModal>
        </FullScreenLayout>
    )
}

export default UserVerifyScreen
