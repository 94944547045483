import {format, intervalToDuration, parseISO} from 'date-fns'
import { nl } from 'date-fns/locale'

export const formatIso = (date: string | Date | undefined) => {
    if(!date) return ''
    let parsed: Date = typeof date === 'string' ? parseISO(date) : date

    //@todo: implement locale detection or from settings
    return format(parsed, 'Pp', {
        locale: nl
    })
}

export const secondsToTime = (seconds: number) => {
    return intervalToDuration({start: 0, end: seconds * 1000})
}
