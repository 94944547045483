import React from 'react'
import {useTranslation} from "react-i18next";
import {formatIso, secondsToTime} from "../../util/dateTime";
import {Link} from "react-router-dom";
import routes from "../../Router/routes";
import Table from "../../Components/Table/Table";
import Duration from "../../Components/DateTime/Duration";

export type Transaction = {
    id: string
    transactionId: string
    started?: string | Date
    stopped?: string | Date
    status: string
    energyConsumed: number
    timeSpend: number
}

interface Props {
    transactions: Transaction[]
}

const TransactionTable = ({ transactions }: Props) => {
    const { t, i18n } = useTranslation()

    const transactionColumns = [
        {
            Header: t('Session'),
            accessor: 'transactionId'
        },
        {
            Header: t('Start'),
            accessor: 'started'
        },
        {
            Header: t('Stopped'),
            accessor: 'stopped'
        },
        {
            Header: t('Status'),
            accessor: 'status'
        },
        {
            Header: t('Energy'),
            accessor: 'energyConsumed'
        },
        {
            Header: t('Duration'),
            accessor: 'timeSpend'
        },
    ]

    const tableData = transactions?.map(transaction => {
        const duration = secondsToTime(transaction.timeSpend || 0)

        return {
            transactionId: <Link to={`${routes.transactions.base}/${transaction.id}`}>{transaction.transactionId}</Link>,
            started: formatIso(transaction.started),
            stopped: formatIso(transaction.stopped),
            status: transaction.status,
            energyConsumed: `${transaction.energyConsumed / 1000} kWh`,
            timeSpend: <Duration duration={duration} />
        }
    }) || []

    if(!transactions || transactions.length === 0) {
        return (<p>{t('NoSessionsAvailableText')}</p>)
    }

    return (<Table columns={transactionColumns} data={tableData} />)
}

export default TransactionTable
