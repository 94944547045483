import React, {ReactNode} from 'react'
import styled from "@emotion/styled";

const StyledH2 = styled.h2`
  font-size: 1.1em;
  text-transform: capitalize;
`

interface Props {
    heading: string
    children: ReactNode
}

export default ({ children, heading }: Props) => {

    return (
        <div className={"uk-flex uk-flex-row uk-flex-between uk-heading-divider"}>
            <StyledH2 className="uk-heading-small">{heading}</StyledH2>
            {children}
        </div>
    )
}

