import React, {ReactNode} from "react";
import styled from "@emotion/styled";
import TopBar from "../Components/Menus/TopBar";
import SideMenu from "../Components/Menus/SideMenu";
import Logo from "../Components/App/Logo";

const Page = styled.div`
  font-family: ${({ theme }) => theme.typography.p.fontFamily};
  font-size: ${({ theme }) => theme.typography.p.fontSize};
  line-height: ${({ theme }) => theme.typography.p.lineHeight};
  color: ${({ theme }) => theme.typography.p.color};
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(229, 231, 237, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`

const Main = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  //height: 100%;
  //width: 100%;
  box-sizing: border-box;
`

const Heading = styled.div`
  padding: 25px 0;
  width: 100%;
  box-sizing: border-box;
`

const PageWrapper = styled.div`
  
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px;
`

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex-grow: 2;
  margin-bottom: 15px;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

const StyledLogo = styled(Logo)`
  height: 25px;
  width: auto;
`

interface Props {
    children: ReactNode
    heading?: ReactNode
}


//@todo: add UI Kit container?
const AppLayout = ({ children, heading }: Props) => {

    return (
        <Page>
            <Main>
                <SideMenu />
                <PageWrapper>
                    {heading &&
                        <Heading>
                            {heading}
                        </Heading>
                    }
                    <Content>
                        {children}
                    </Content>
                    <LogoContainer>
                        <StyledLogo />
                    </LogoContainer>
                </PageWrapper>
            </Main>
        </Page>
    )
}

export default AppLayout
