import React from 'react'
import styled from "@emotion/styled";
import SideMenuItem from "./SideMenuItem";
import routes from "../../Router/routes";
import {
    BuildingIcon,
    ChargingStationIcon,
    CogIcon, CoinsIcon,
    CreditCardIcon,
    HomeIcon,
    IglooIcon,
    MapIcon,
    ReceiptIcon
} from "../Icons";

const SideBar = styled.div`
  height: 100%;
  width: 10%;
  max-width: 108px;
  min-width: 75px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 25px 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Middle = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
`

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: ${({ theme }) => `1px solid ${theme.colors.grayLight}`};
  display: flex;
  justify-content: center;
  align-items: center;
`

const SideMenu = () => {

    return (
        <SideBar>
            <Top>
                <Avatar>
                    <IglooIcon />
                </Avatar>
            </Top>
            <Middle>
                <SideMenuItem to={routes.base} label={'Home'} icon={<HomeIcon />} />
                <SideMenuItem to={routes.locations.base} label={'Locations'} icon={<MapIcon />} />
                <SideMenuItem to={routes.chargeStations.base} label={'Charge stations'} icon={<ChargingStationIcon/>} />
                <SideMenuItem to={routes.customers.base} label={'Customers'} icon={<BuildingIcon/>} />
                <SideMenuItem to={routes.transactions.base} label={'Transactions'} icon={<ReceiptIcon/>} />
                <SideMenuItem to={routes.cards.base} label={'Charge cards'} icon={<CreditCardIcon/>} />
                <SideMenuItem to={routes.rateplan.base} label={'Rate plan'} icon={<CoinsIcon />} />

                {/*CardHolder*/}
            </Middle>
            <Bottom>
                <SideMenuItem to={routes.settings.base} label={'Settings'} icon={<CogIcon/>} />
            </Bottom>
        </SideBar>
    )
}

export default SideMenu
