import React, {useState} from 'react'
import AppLayout from "../../Layouts/AppLayout";
import {Card, CardBody, Grid, Section} from "uikit-react";
import {useParams} from "react-router";
import {gql, useQuery} from "@apollo/client";
import styled from "@emotion/styled";
import Map from "../../Components/Map/Map";
import Label from "../../Components/Typography/Label";
import CardTitle from "../../Components/Typography/CardTitle";
import {useTranslation} from "react-i18next";
import TransactionTable from "../../Containers/Transaction/TransactionTable";
import {parseISO} from "date-fns";
import ChargeStationConnectorForm from "./ChargeStationConnectorForm";
import ChargeStationConnectorsTable from "./ChargeStationConnectorsTable";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import CardHeader from "../../Components/Headings/CardHeader";

const StyledMap = styled(Map)`
  height: 250px;
`

const chargePointDetailsQuery = gql`
    query GetChargePointDetailsQuery($chargeStationId: ID!) {
        chargePoint(chargepointId: $chargeStationId) {
            id
            name
            location {
                id
                name
                address
                city
            }
            identifier
            status
            lastSeen
            connectors {
                id
                connectorId
            }
            sessions {
                id
                status
                transactionId
                chargeConnector
                started
                stopped
                energyConsumed
                timeSpend
            }
        }
    }
`

const ChargeStationDetailsScreen = () => {
    const { t, i18n } = useTranslation()
    const { stationId } = useParams()
    const [ showConnectorForm, setShowConnectorForm ] = useState(false)
    const { loading, data } = useQuery(chargePointDetailsQuery, {
        variables: { chargeStationId: stationId },
        pollInterval: 5000,
        fetchPolicy: "no-cache"
    }) || {}

    if(!stationId) return null

    const mapOptions = {
        interactive: false
    }

    const { chargePoint } = data || {}

    return (
        <AppLayout>
            <Grid size='small'>
                <Section width='2-3'>
                    <Card size='small'>
                        <CardBody>

                            <Grid>
                                <Section width='1-2'>
                                    <Label>{t('ChargeStationName')}</Label>
                                    <p>{chargePoint?.name}</p>
                                    <Label>ID</Label>
                                    <p>{chargePoint?.identifier}</p>
                                </Section>
                                <Section width='1-2'>
                                    <Label>Status</Label>
                                    <p>{chargePoint?.status}</p>
                                    <Label>Last update</Label>
                                    <p>{`${parseISO(chargePoint?.lastSeen).toLocaleDateString()} ${parseISO(chargePoint?.lastSeen).toLocaleTimeString()}`}</p>
                                </Section>
                            </Grid>

                        </CardBody>
                    </Card>
                </Section>
                <Section width='1-3'>
                    {/*<Card size='small'>*/}
                    {/*    <CardBody>*/}
                    {/*        /!*<StyledMap lat={'52.264526'} long={'6.297641'} zoom={9} options={mapOptions}/>*!/*/}
                    {/*    </CardBody>*/}
                    {/*</Card>*/}
                </Section>
            </Grid>
            <Grid size='small'>
                <Section width='expand'>
                    <Card size="small">
                        <CardBody>
                            <CardHeader heading={t('Connectors')}>
                                {!showConnectorForm &&
                                    <PrimaryButton onClick={() => setShowConnectorForm(true)}>
                                        {chargePoint?.connectors?.length > 0 ? t('Edit') : t('Add') }
                                    </PrimaryButton>
                                }
                            </CardHeader>
                            {!showConnectorForm && <ChargeStationConnectorsTable chargeStationId={stationId} /> }
                            {chargePoint?.connectors?.length === 0 && <p>{t('NoConnectorsConfigured')}</p>}
                            {showConnectorForm &&
                                <ChargeStationConnectorForm
                                    chargeStationId={stationId}
                                    hideForm={() => setShowConnectorForm(false)}
                                />
                            }
                        </CardBody>
                    </Card>
                </Section>
            </Grid>
            <Grid>
                <Section width='expand'>
                    <Card size='small'>
                        <CardBody>
                            <CardTitle>{t('ChargeSessions')}</CardTitle>
                            <TransactionTable transactions={chargePoint?.sessions} />
                        </CardBody>
                    </Card>
                </Section>
            </Grid>
        </AppLayout>
    )

}

export default ChargeStationDetailsScreen
