const colors = {
    grayDark: "#474A4F",
    grayLight: "rgba(86,86,88,0.53)",
    offWhite: "rgba(231,231,231,0.2)",
    orange: "#FF5E03",
    yellow: "#F2C611",
    yellowLight: "#FFF4C9",
    white: "#FFFFFF"
}

export default {
    ...colors,
    primary: colors.yellow
}
