import React, {useEffect, useState} from 'react'
import styled from "@emotion/styled";
import FullScreenLayout from "../../Layouts/FullScreenLayout";
import Logo from "../../Components/App/Logo";
import {useForm} from "react-hook-form";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {useTranslation} from "react-i18next";
import TextInput from "../../Components/Forms/TextInput";
import countries from '../../countries.json';
import SelectInput, {SelectOption} from "../../Components/Forms/SelectInput";
import {gql, useMutation} from "@apollo/client";
import {currencies} from "../../constants";
import {useNavigate} from "react-router-dom";
import {customerId, userToken} from "../../App";
import routes from "../../Router/routes";

export const OnboardingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //height: 100%;
  width: 100%;
`

export const OnboardingModal = styled.div`
  max-width: 800px;
  //max-height: 80%;
  margin: 40px 0;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 32px 0 rgba(71,74,79,0.29);
  background-color: #FFFFFF;
  padding: 16px;
`

const FormContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  
  label, button {
    margin-top: 16px;
    display: block;
  }
`

const Divider = styled.div`
  margin-top: 16px;
`

const WelcomeWrapper = styled.div`
  margin: 16px auto;
  
  width: 75%;
`

interface SaveCustomerProps {
    company: string
    address: string
    address2?: string
    postalCode: string
    city: string
    province?: string
    country: string
    baseCurrency: string
    defaultPayInDays: string
    invoiceNo: string
    invoicePrefix?: string
    paymentAccount: string
    supportEmail: string
}

const CREATE_CUSTOMER_MUTATION = gql`
    mutation CreateCustomer(
        $name: String!
        $address: String!
        $address2: String
        $postalCode: String!
        $city: String!
        $province: String
        $country: String!
        $baseCurrency: String!
        $defaultPayInDays: Int!
        $invoiceNo: Int!
        $invoicePrefix: String
        $paymentAccount: String!
        $supportEmail: String!
    ) {
        createCustomer(input: {
            name: $name,
            baseCurrency: $baseCurrency
            supportEmail: $supportEmail
            billingAddress: {
                address: $address
                address1: $address2
                postalCode: $postalCode
                city: $city
                province: $province
                country: $country
            }
            invoiceSettings: {
              defaultPayInDays: $defaultPayInDays,
              invoiceNo: $invoiceNo,
              invoicePrefix: $invoicePrefix,
              paymentAccount: $paymentAccount
            }
        }) {
            id
            token
        }
    }
`

//@todo: success message
//@todo: error message
//@todo: indicator required field

type FormValues = {
    company: string,
    address: string,
    address2?: string,
    postalCode: string,
    city: string,
    province?: string,
    country: string,
    baseCurrency: string
    defaultPayInDays: string
    invoiceNo: string
    invoicePrefix?: string
    paymentAccount: string
    supportEmail: string
}

const CustomerOnboardingScreen = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();
    const { t, i18n } = useTranslation()
    const [ loading, setLoading ] = useState(false)
    const [ saveCustomerMutation, { data: customerData }] = useMutation(CREATE_CUSTOMER_MUTATION)
    const navigate = useNavigate()

    const countryOptions = countries.map(country => ({ value: country['alpha-2'], label: country.name })) as [SelectOption]
    const currencyOptions = currencies.map(currency => ({ value: currency, label: currency })) as [SelectOption]

    const saveCustomer = ({
                              company,
                              address,
                              address2,
                              postalCode,
                              city,
                              province,
                              country,
                              baseCurrency,
                              defaultPayInDays,
                              invoiceNo,
                              invoicePrefix,
                              paymentAccount,
                              supportEmail
                          }: SaveCustomerProps) => {
        setLoading(true)
        try {
            saveCustomerMutation({
                variables: {
                    name: company,
                    baseCurrency,
                    address,
                    address2,
                    postalCode,
                    city,
                    province,
                    country,
                    defaultPayInDays: parseInt(defaultPayInDays),
                    invoiceNo: parseInt(invoiceNo) - 1,
                    invoicePrefix,
                    paymentAccount,
                    supportEmail
                }
            })
        } catch (err) {
            console.error(err)
            setLoading(false)
        }
    }

    useEffect(() => {
        if(customerData?.createCustomer?.id && customerData?.createCustomer?.token) {
            userToken(customerData?.createCustomer?.token)
            customerId(customerData?.createCustomer?.id)
            localStorage.setItem("userToken", customerData?.createCustomer?.token)
            localStorage.setItem("customerId", customerData?.createCustomer?.id)
            navigate(`${routes.onboarding.base}/${routes.onboarding.subscription}`)
        }
        setLoading(false)
    }, [customerData])

    return (
        <FullScreenLayout>
            <OnboardingWrapper>
                <OnboardingModal>
                    <Logo />

                    <WelcomeWrapper>
                        <h1>Welcome!</h1>
                        <p>
                            Welcome to Ionized and thank you for creating an account. We need a bit more information to
                            get you setup. Please enter your company details and click next.
                        </p>
                    </WelcomeWrapper>

                    <FormContainer>
                        <form onSubmit={handleSubmit(saveCustomer)}>
                            <TextInput label={t('Company name')} {...register('company', { required: true })} />

                            <TextInput label={t('Address')} {...register('address', { required: true })}/>
                            <TextInput {...register('address2', { required: false })}/>

                            <TextInput label={t('Postal code')} {...register('postalCode', { required: true })}/>
                            <TextInput label={t('City')} {...register('city', { required: true })}/>

                            <TextInput label={t('Province / state')} {...register('province', { required: false })}/>

                            <Divider />
                            <SelectInput options={countryOptions} label={t('Country')} {...register('country', { required: true })} defaultValue='NL'/>

                            <Divider />
                            <TextInput label={t('Support e-mail address')} {...register('supportEmail', { required: true })}/>

                            <Divider />
                            <h5>{t('Invoice settings')}</h5>
                            <SelectInput options={currencyOptions} label={t('Currency')} {...register('baseCurrency', { required: true} )} defaultValue={"EUR"} />

                            <TextInput label={t('Default payment term')} {...register('defaultPayInDays', { required: true })}/>
                            <TextInput label={t('Starting invoice number')} {...register('invoiceNo', { required: true })}/>
                            <TextInput label={t('Invoice prefix')} {...register('invoicePrefix', { required: false })}/>
                            <TextInput label={t('Bank account')} {...register('paymentAccount', { required: true })}/>

                            <PrimaryButton type='submit' disabled={loading}>
                                {t('Next')}
                            </PrimaryButton>
                        </form>
                    </FormContainer>
                </OnboardingModal>
            </OnboardingWrapper>
        </FullScreenLayout>
    )
}

export default CustomerOnboardingScreen
