import React from "react";
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import {useTranslation} from "react-i18next";
import {gql, useQuery} from "@apollo/client";
import TransactionTable from "../../Containers/Transaction/TransactionTable";
import {Card, CardBody} from "uikit-react";

const sessionsQuery = gql`
    query GetChargeSessions {
        getChargeSessions {
            id
            transactionId
            status
            idTag
            started
            stopped
            timeSpend
            energyConsumed
        }
    }
`

const TransactionsScreen = () => {
    const { t, i18n } = useTranslation()
    const { loading, data } = useQuery(sessionsQuery)

    return (
        <AppLayout heading={
            <PageHeader title={t('Charge sessions')} />
        }>
            <Card>
                <CardBody>
                    {!loading && <TransactionTable transactions={data?.getChargeSessions} /> }
                </CardBody>
            </Card>
        </AppLayout>
    )

}

export default TransactionsScreen
