import React, {useEffect} from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import {useTranslation} from "react-i18next";
import {Card, CardBody} from "uikit-react";
import TextInput from "../../Components/Forms/TextInput";
import SelectInput from "../../Components/Forms/SelectInput";
import {gql, useMutation, useQuery} from "@apollo/client";
import {Client, ClientsQueryResult, useChargeCardsQuery, useClientsQuery} from "../../generated/graphql";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {useForm} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import routes from "../../Router/routes";

const CLIENTS_QUERY = gql`
    query Clients {
      clients {
        id
        name
      }
    }
`

const CARDS_QUERY = gql`
    query ChargeCards {
      getChargeCards {
        active
        cardNumber
        id
        customer {
            id
        }
        cardHolder {
          id
        }
      }
    }
`

const CREATE_CARD_HOLDER_MUTATION = gql`
    mutation CreateCardHolder($input: CardHolderInput) {
      createCardHolder(input: $input) {
        id
        client {
          id
        }
      }
    }
`

type FormData = {
    firstName: string
    lastName: string
    clientId: string
    cardId: string
}

export default () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { data: clients, loading: clientsLoading } = useClientsQuery()
    const { data: cards, loading: cardsLoading } = useChargeCardsQuery()
    const { register, handleSubmit, watch, formState: { errors } } = useForm<FormData>();
    const [ createCardHolder, { data: holderResult, loading, error: saveError }] = useMutation(CREATE_CARD_HOLDER_MUTATION, {
        refetchQueries: ['Clients', 'GetClients']
    })

    const honorificsOptions = [
        {
            label: 'Mx',
            value: 'mx'
        },
        {
            label: 'Mr',
            value: 'mr'
        },
        {
            label: 'Ms',
            value: 'ms'
        }
    ]

    const clientsOptions = clients?.clients?.map(client => ({
        label: client?.name || "",
        value: client?.id || ""
    })) || []

    const cardsOptions = cards?.getChargeCards?.filter(card => card?.active && !card.cardHolder?.id).map(card => ({
        label: card?.cardNumber || "",
        value: card?.id || ""
    })) || []

    const submit = (data: FormData) => {
        createCardHolder({
            variables: {
                input: {
                    chargeCardId: data.cardId,
                    clientId: data.clientId,
                    firstName: data.firstName,
                    lastName: data.lastName
                }
            }
        })
    }

    useEffect(() => {
        if(holderResult?.createCardHolder?.id) {
            navigate(`${routes.customers.base}/${holderResult.createCardHolder?.client?.id}`)
        }
    }, [holderResult])

    //@ts-ignore
    const initialClient = location?.state?.clientId

    return (
        <AppLayout heading={<PageHeader title={t('CreateCardHolderTitle')} />}>
            <form onSubmit={handleSubmit(submit)}>
                <Card>
                    <CardBody>

                        {/*<SelectInput options={honorificsOptions} />*/}
                        <TextInput label={"First name"} {...register('firstName', { required: true })} />
                        <TextInput label={"Last name"} {...register('lastName', { required: true })} />

                        <SelectInput
                            defaultValue={initialClient}
                            options={clientsOptions}
                            label={'Customer'}
                            {...register('clientId', { required: true })}
                        />
                        <SelectInput options={cardsOptions} label={'Charge card'} {...register('cardId', { required: true })} />

                    </CardBody>
                </Card>
                <div className="uk-margin-top uk-align-right">
                    <PrimaryButton disabled={loading}>{t('Save')}</PrimaryButton>
                </div>
            </form>
        </AppLayout>
    )
}