import React, {useEffect} from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Button, Card, CardBody, Grid, Section} from "uikit-react";
import TextInput from "../../Components/Forms/TextInput";
import {useFieldArray, useForm} from "react-hook-form";
import SelectInput, {SelectOption} from "../../Components/Forms/SelectInput";
import {currencies} from "../../constants";
import CurrencyInput from "../../Components/Forms/CurrencyInput";
import styled from "@emotion/styled";
import {gql, useMutation} from "@apollo/client";
import NumberInput from "../../Components/Forms/NumberInput";
import routes from "../../Router/routes";


const ButtonContainer = styled.div`
  margin-top: 15px;
  text-align: right;
`

type Rates = {
    description: string
    price: number
    unit: string
    taxPercentage: number
    priceIncludesTax: boolean
}

type FormData = {
    currency: string
    name: string
    rates: [Rates]
}

const CREATE_RATEPLAN_MUTATION = gql`
    mutation CreateRatePlan($input: RatePlanInput) {
      createRatePlan(input: $input) {
        id
      }
    }
`

const CreateRatePlanScreen = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const { register, handleSubmit, control, formState: { errors } } = useForm<FormData>()
    const [ createRatePlan, { loading, data, error }] = useMutation(CREATE_RATEPLAN_MUTATION)

    const currencyOptions: SelectOption[] = currencies.map(currency => ({
        label: currency,
        value: currency
    }))

    const RateUnits = [
        {
            label: t('UnitPerKwh'),
            value: "kWh"
        },
        {
            label: t('UnitPerMinute'),
            value: "minute"
        },
        {
            label: t('UnitFixed'),
            value: "fixed"
        }
    ]

    const taxOptions = [
        {
            label: t('True'),
            value: "true"
        },
        {
            label: t('False'),
            value: "false"
        }
    ]

    const { fields, append, remove } = useFieldArray({
        control,
        name: "rates"
    })

    useEffect(() => {
        append({
            description: "",
            price: 0,
            unit: "kWh",
            taxPercentage: 0,
            priceIncludesTax: true
        })
    }, [])

    const submitPlan = (data: FormData) => {
        data.rates.forEach(rate => {
            rate.priceIncludesTax = Boolean(rate.priceIncludesTax)
        })
        createRatePlan({
            refetchQueries: [
              'RatePlans'
            ],
            variables: {
                input: data
            }
        })
    }

    useEffect(() => {
        if(data?.createRatePlan?.id) {
            navigate(`${routes.rateplan.base}`)
        }
    }, [data])

    return (
        <AppLayout heading={<PageHeader title={t('NewRatePlanTitle')} />}>
            <form onSubmit={handleSubmit(submitPlan)}>
            <Grid gutter='small'>
                <Section width='expand'>
                    <Card>
                        <CardBody>
                            <div className="uk-flex uk-flex-row">
                                <div>
                                    <TextInput label={t('Name')} {...register('name', { required: true })}/>
                                </div>
                                <div className="uk-margin-small-left">
                                    <SelectInput
                                        options={currencyOptions}
                                        label={t('Currency')}
                                        defaultValue={'EUR'}
                                        {...register('currency', { required: true })}
                                    />
                                </div>
                            </div>
                            <h4>Rates</h4>

                            {fields.map((item, index) => (
                                <div className="uk-flex uk-flex-row" key={item.id}>
                                    <div className="uk-margin-small-left">
                                        <SelectInput
                                            options={RateUnits}
                                            label={t('Unit')}
                                            {...register(`rates.${index}.unit` as 'rates.0.unit')}
                                        />
                                    </div>
                                    <div className="uk-margin-small-left">
                                        <TextInput
                                            label={t('Description')}
                                            {...register(`rates.${index}.description` as 'rates.0.description',
                                                { required: true }
                                            )}
                                        />
                                    </div>
                                    <div className="uk-margin-small-left">
                                        <CurrencyInput
                                            label={t('Price')}
                                            {...register(`rates.${index}.price` as 'rates.0.price',
                                                {
                                                    required: true,
                                                    valueAsNumber: true
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className="uk-margin-small-left">
                                        <NumberInput
                                            label={t('Tax%')}
                                            {...register(`rates.${index}.taxPercentage` as 'rates.0.taxPercentage',
                                                {
                                                    required: true,
                                                    valueAsNumber: true
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className="uk-margin-small-left">
                                        <SelectInput
                                            options={taxOptions}
                                            label={t('PriceIncludesTax')}
                                            {...register(`rates.${index}.priceIncludesTax` as 'rates.0.priceIncludesTax',
                                                {
                                                    required: true,
                                                    setValueAs: v => Boolean(v)
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className="uk-margin-small-left">
                                        <Button
                                            className="uk-button uk-button-primary uk-button-small"
                                            style={{marginTop: "33px"}}
                                            onClick={() => append({
                                                description: "",
                                                price: 0,
                                                unit: "kWh",
                                                taxPercentage: 0,
                                                priceIncludesTax: true
                                            })}
                                        >
                                            +
                                        </Button>

                                        {fields.length > 1 &&
                                            <Button
                                                className="uk-button uk-button-secondary uk-button-small"
                                                style={{marginTop: "33px", marginLeft: "5px"}}
                                                onClick={() => remove(index)}
                                            >
                                                -
                                            </Button>
                                        }
                                    </div>
                                </div>
                            ))}

                        </CardBody>
                    </Card>
                </Section>
            </Grid>
            <ButtonContainer>
                <PrimaryButton type='submit' disabled={loading}>Save</PrimaryButton>
            </ButtonContainer>
            </form>
        </AppLayout>
    )
}

export default CreateRatePlanScreen
