import React, {InputHTMLAttributes} from "react";
import TextInput from "./TextInput";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
}

const PasswordInput = React.forwardRef<HTMLInputElement, Props>(({ className, ...props}, ref) => {
    return (
        <TextInput type='password' {...props} ref={ref} />
    )
})

export default PasswordInput
