import React, {useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import {Card, CardBody, Grid, Section} from "uikit-react";
import Label from "../../Components/Typography/Label";
import countries from '../../util/countries.json'
import styled from "@emotion/styled";
import ClientCardHoldersTab from "./ClientCardHoldersTab";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {getI18n, useTranslation} from "react-i18next";
import routes from "../../Router/routes";
import ClientChargeSessionsTab from "./ClientChargeSessionsTab";
import NotFoundScreen from "../NotFoundScreen";

const getClientQuery = gql`
    query GetClient($clientId: ID!) {
        client(clientId: $clientId) {
            id
            name
            email
            companyRegistration
            taxId
            type
            billingAddress {
                address1
                address2
                state
                postalCode
                city
                country
            }
            postalAddress {
                address1
                address2
                state
                postalCode
                city
                country
            }
            invoiceSettings {
              billingEmail
              autoInvoice
              billingInterval
              payInDays
            }
        }
    }
`

const Container = styled.div`
  width: 100%;
  margin-top: 15px;
`

const ClientDetailsScreen = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const { clientId } = useParams()
    const { loading, data } = useQuery(getClientQuery, { variables: { clientId }})
    const [ activeTab, setActiveTab ] = useState('cardHolders')

    const { client } = data || {}

    //@todo: nice loading page
    if(!client) return (<NotFoundScreen />)

    return (
        <AppLayout heading={
            <PageHeader title={client?.name || ""} />
        }>

            <Grid gutter='small'>

                <Section width='expand'>
                    <Card>
                        <CardBody>

                            <Grid gutter='small'>
                                <Section width='1-4'>
                                    <Label>Name</Label>
                                    <p>{client.name}</p>

                                    <Label>E-mail address</Label>
                                    <p>{client.email}</p>

                                </Section>
                                <Section width='1-4'>
                                    <Label>Type</Label>
                                    <p>{client.type}</p>

                                    <Label>Registration</Label>
                                    <p>{client.companyRegistration}</p>

                                    <Label>Tax / VAT id</Label>
                                    <p>{client.taxId}</p>
                                </Section>
                                <Section width='1-4'>
                                    <Label>{t('autoInvoiceLabel')}</Label>
                                    <p>{client.invoiceSettings.autoInvoice ? t('yes') : t('no')}</p>

                                    <Label>{t('billingEmailLabel')}</Label>
                                    <p>{client.invoiceSettings.billingEmail}</p>
                                </Section>
                                <Section width='1-4'>
                                    <Label>{t('billingIntervalLabel')}</Label>
                                    <p>{billingIntervalToLabel(client.invoiceSettings.billingInterval)}</p>

                                    <Label>{t('paymentTermsLabel')}</Label>
                                    <p>{client.invoiceSettings.payInDays} {t('days')}</p>
                                </Section>
                            </Grid>

                        </CardBody>
                    </Card>
                </Section>

            </Grid>

            <Grid gutter='small'>

                <Section width='1-2'>
                    <Card>
                        <CardBody>
                            <Label>Billing address</Label>
                            <p>{client.billingAddress?.address1}</p>
                            <p>{client.billingAddress?.address2}</p>

                            <Grid gutter={'small'}>
                                <Section width={'1-2'}>
                                    <Label>Postal code</Label>
                                    <p>{client.billingAddress?.postalCode}</p>
                                </Section>
                                <Section>
                                    <Label>City</Label>
                                    <p>{client.billingAddress?.city}</p>
                                </Section>
                            </Grid>
                            <Grid gutter={'small'}>
                                <Section width={'1-2'}>
                                    <Label>State</Label>
                                    <p>{client.billingAddress?.state}</p>
                                </Section>
                                <Section>
                                    <Label>Country</Label>
                                    {/*//@ts-ignore*/}
                                    <p>{countries[client.billingAddress?.country]}</p>
                                </Section>
                            </Grid>
                        </CardBody>
                    </Card>
                </Section>

                <Section width='1-2'>
                    <Card>
                        <CardBody>
                            <Label>Shipping address</Label>
                            <p>{client.postalAddress?.address1}</p>
                            <p>{client.postalAddress?.address2}</p>

                            <Grid gutter={'small'}>
                                <Section width={'1-2'}>
                                    <Label>Postal code</Label>
                                    <p>{client.postalAddress?.postalCode}</p>
                                </Section>
                                <Section>
                                    <Label>City</Label>
                                    <p>{client.postalAddress?.city}</p>
                                </Section>
                            </Grid>
                            <Grid gutter={'small'}>
                                <Section width={'1-2'}>
                                    <Label>State</Label>
                                    <p>{client.postalAddress?.state}</p>
                                </Section>
                                <Section>
                                    <Label>Country</Label>
                                    {/*//@ts-ignore*/}
                                    <p>{countries[client.postalAddress?.country]}</p>
                                </Section>
                            </Grid>
                        </CardBody>
                    </Card>
                </Section>

            </Grid>


            <Container>
                <Card>
                    <CardBody>
                        <div className="uk-flex uk-flex-column is-full-width">
                        <div className={"uk-align-right is-fullwidth uk-margin-remove-bottom"}>
                            <PrimaryButton
                                className="uk-align-right uk-margin-remove-bottom"
                                onClick={() => navigate(
                                    `${routes.cardHolders.base}/${routes.cardHolders.create}`,
                                    {
                                        state: {
                                            clientId
                                        }
                                    }
                                )}
                            >
                                {t('Add card holder')}
                            </PrimaryButton>
                        </div>
                        <div>
                            <ul className={'uk-tab'}>
                                <li className={activeTab === 'cardHolders' ? 'uk-active' : ''}>
                                    <a href='#' onClick={() => setActiveTab('cardHolders')}>{t('CardHolders')}</a>
                                </li>
                                <li className={activeTab === 'chargeSessions' ? 'uk-active' : ''}>
                                    <a href='#' onClick={() => setActiveTab('chargeSessions')}>{t('ChargeSessions')}</a>
                                </li>
                                <li>
                                    <a href='#'>Invoices</a>
                                </li>
                            </ul>
                        </div>
                        </div>

                        {activeTab === 'cardHolders' && clientId &&
                            <ClientCardHoldersTab clientId={clientId} />
                        }

                        {activeTab === 'chargeSessions' && clientId &&
                            <ClientChargeSessionsTab clientId={clientId} />
                        }

                    </CardBody>
                </Card>
            </Container>

        </AppLayout>
    )
}

export default ClientDetailsScreen

function billingIntervalToLabel(interval: string) {
    const { t } = getI18n()

    switch(interval) {
        case "DAILY":
            return t('daily')
        case "WEEKLY":
            return t('weekly')
        case "MONTHLY":
            return t('monthly')
        case "QUARTERLY":
            return t('quarterly')
    }
}