import React, {useEffect} from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import {Card, CardBody, Grid, Section} from "uikit-react";
import TextInput from "../../Components/Forms/TextInput";
import SelectInput, {SelectOption} from "../../Components/Forms/SelectInput";
import Label from "../../Components/Typography/Label";
import CountrySelect from "../../Components/Forms/CountrySelect";
import {useForm} from "react-hook-form";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import styled from "@emotion/styled";
import {gql, useMutation} from "@apollo/client";
import {useNavigate} from "react-router";
import routes from "../../Router/routes";

type FormData = {
    name: string
    type: string
    email: string
    invoiceEmail?: string
    companyRegistration?: string
    taxId?: string
    billingAddress: string
    billingAddress1: string
    billingPostalCode: string
    billingCity: string
    billingState: string
    billingCountry: string
    shippingAddress: string
    shippingAddress1: string
    shippingPostalCode: string
    shippingCity: string
    shippingState: string
    shippingCountry: string
    autoInvoice: string
    billingInterval?: string
    payInDays?: string
}

const createClientMutation = gql`
    mutation CreateClient(
        $name: String
        $email: String
        $companyRegistration: String
        $taxId: String
        $type: ClientType
        $billingAddress: String
        $billingAddress1: String
        $billingPostalCode: String
        $billingCity: String
        $billingState: String
        $billingCountry: String
        $shippingAddress: String
        $shippingAddress1: String
        $shippingPostalCode: String
        $shippingCity: String
        $shippingState: String
        $shippingCountry: String
        $autoInvoice: Boolean!
        $billingEmail: String
        $billingInterval: BillingInterval
        $payInDays: Int
    ) {
        createClient(input: {
            name: $name
            email: $email
            companyRegistration: $companyRegistration
            taxId: $taxId
            type: $type
            billingAddress: {
                address1: $billingAddress
                address2: $billingAddress1
                postalCode: $billingPostalCode
                city: $billingCity
                state: $billingState
                country: $billingCountry
            }
            postalAddress: {
                address1: $shippingAddress
                address2: $shippingAddress1
                postalCode: $shippingPostalCode
                city: $shippingCity
                state: $shippingState
                country: $shippingCountry
            }
            invoiceSettings: {
              autoInvoice: $autoInvoice,
              billingEmail: $billingEmail,
              billingInterval: $billingInterval,
              payInDays: $payInDays
            }
        }) {
            id
        }
    }
`

const ButtonContainer = styled.div`
  margin-top: 15px;
  text-align: right;
`

const CreateClientScreen = () => {
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState: { errors } } = useForm<FormData>();
    const [ createClient, { loading, data: clientResult }]  = useMutation(createClientMutation)

    const onFormSubmit = (data: FormData) => {
        console.log({data})
        createClient({
            variables: {
                name: data.name,
                email: data.email,
                companyRegistration: data.companyRegistration,
                taxId: data.taxId,
                type: data.type,
                billingAddress: data.billingAddress,
                billingAddress1: data.billingAddress1,
                billingPostalCode: data.billingPostalCode,
                billingCity: data.billingCity,
                billingState: data.billingState,
                billingCountry: data.billingCountry,
                shippingAddress: data.shippingAddress,
                shippingAddress1: data.shippingAddress1,
                shippingPostalCode: data.shippingPostalCode,
                shippingCity: data.shippingCity,
                shippingState: data.shippingState,
                shippingCountry: data.shippingCountry,
                autoInvoice: data.autoInvoice === "true" ? true : false,
                billingEmail: data.invoiceEmail,
                billingInterval: data.billingInterval,
                payInDays: data.payInDays ? parseInt(data.payInDays) : undefined
            }
        })
    }

    useEffect(() => {
        if(clientResult?.createClient?.id) {
            navigate(`${routes.customers.base}/${clientResult.createClient.id}`)
        }
    }, [clientResult])

    const customerTypeOptions: SelectOption[] = [
        {
            label: 'Business',
            value: 'BUSINESS'
        },
        {
            label: 'Private',
            value: 'PRIVATE'
        }
    ]

    const autoInvoiceOptions: SelectOption[] = [
        {
            label: 'Yes',
            value: 'true'
        },
        {
            label: 'No',
            value: 'false'
        }
    ]

    const billingIntervalOptions: SelectOption[] = [
        {
            label: 'Daily',
            value: 'DAILY'
        },
        {
            label: 'Weekly',
            value: 'WEEKLY'
        },
        {
            label: 'Monthly',
            value: 'MONTHLY'
        },
        {
            label: 'Quarterly',
            value: 'QUARTERLY'
        }
    ]

    return (
        <AppLayout heading={
            <PageHeader title={'New customer'} />
        }>

            <form onSubmit={handleSubmit(onFormSubmit)}>
            <Grid gutter='small'>
                <Section width={'expand'}>
                    <Card size='small'>
                        <CardBody>
                            <Grid gutter='small'>
                                <Section width='1-2'>
                                    <TextInput label={'Name'} {...register('name')} />
                                    <SelectInput options={customerTypeOptions} label={'Type of customer'} {...register('type')}/>
                                    <TextInput label={'Company registration no'} {...register('companyRegistration')} />
                                </Section>

                                <Section width='1-2'>
                                    <TextInput label={'E-mail address'} {...register('email')} />
                                    <TextInput label={'Tax / VAT id'} {...register('taxId')} />
                                </Section>
                            </Grid>
                        </CardBody>
                    </Card>
                </Section>

                <Section width={'1-3'}>
                    <Card size='small'>
                        <CardBody>
                            <SelectInput options={autoInvoiceOptions} label={'Auto send invoice'} {...register('autoInvoice')} />
                            <TextInput label={'E-mail address for invoices'} {...register('invoiceEmail')} />
                            <SelectInput options={billingIntervalOptions} label={'Billing interval'} {...register('billingInterval')} />
                            <TextInput label={'Payment terms'} {...register('payInDays')} />
                        </CardBody>
                    </Card>
                </Section>
            </Grid>

            <Grid gutter='small'>
                <Section width='1-2'>
                    <Card size='small'>
                        <CardBody>
                            <Label>Billing address</Label>

                            <TextInput label={'Address'} {...register('billingAddress')}/>
                            <TextInput {...register('billingAddress1')}/>
                            <TextInput label={'Postal code'} {...register('billingPostalCode')}/>
                            <TextInput label={'City'} {...register('billingCity')}/>
                            <TextInput label={'Province / State'} {...register('billingState')}/>
                            <CountrySelect label={'Country'} defaultValue={'NL'} {...register('billingCountry')}/>
                        </CardBody>
                    </Card>
                </Section>

                <Section width='1-2'>
                    <Card size='small'>
                        <CardBody>
                            <Label>Shipping address</Label>

                            <TextInput label={'Address'} {...register('shippingAddress')}/>
                            <TextInput {...register('shippingAddress1')}/>
                            <TextInput label={'Postal code'} {...register('shippingPostalCode')}/>
                            <TextInput label={'City'} {...register('shippingCity')}/>
                            <TextInput label={'Province / State'} {...register('shippingState')}/>
                            <CountrySelect label={'Country'} defaultValue={'NL'} {...register('shippingCountry')}/>
                        </CardBody>
                    </Card>
                </Section>
            </Grid>
            <ButtonContainer>
                <PrimaryButton type='submit' disabled={loading}>Save</PrimaryButton>
            </ButtonContainer>
            </form>

        </AppLayout>
    )
}

export default CreateClientScreen
