import React, {useEffect} from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import PageHeader from "../../Components/Headings/PageHeader";
import {Card, CardBody} from "uikit-react";
import TextInput from "../../Components/Forms/TextInput";
import SelectInput, {SelectOption} from "../../Components/Forms/SelectInput";
import styled from "@emotion/styled";
import {gql, useMutation} from "@apollo/client";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router";
import routes from "../../Router/routes";

const ButtonContainer = styled.div`
  margin-top: 15px;
  text-align: right;
`

type FormData = {
    idTag: String
    type: String
    number: String
}

const createCardMutation = gql`
    mutation CreateChargeCard(
        $idTag: String!
        $type: CardType
        $number: String
    ) {
        createChargecard(input: {
            idTag: $idTag
            cardNumber: $number
            type: $type
        }) {
            id
            idTag
        }
    }
`

const AddCardScreen = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<FormData>();
    const [ save, { data }] = useMutation(createCardMutation)
    const navigate = useNavigate()

    const cardOptions: SelectOption[] = [
        {
            label: 'RFiD Card',
            value: 'RFID'
        }
    ]

    const saveCard = (data: FormData) => {
        console.log({data})
        const idTag = data.idTag.replaceAll(':', '')

        save({
            variables: {
                idTag: idTag,
                type: 'RFID',
                number: data.number
            }
        })
    }

    useEffect(() => {
        if(data?.createChargecard?.id) {
            navigate(`${routes.cards.base}/${data?.createChargecard?.id}`)
        }
    }, [data])

    return (
        <AppLayout heading={
            <PageHeader title={'New card'}/>
        }>

            {/*// @ts-ignore*/}
            <form onSubmit={handleSubmit(saveCard)}>
                <Card>
                    <CardBody>
                            <TextInput label={'Card UID token'} {...register('idTag')} />
                            <TextInput label={'Card number'} {...register('number')} />
                            <SelectInput options={cardOptions} defaultValue={'RFID'} disabled label={'Type'} {...register('type')} />

                    </CardBody>
                </Card>

                <ButtonContainer>
                    <PrimaryButton type='submit'>
                        Save
                    </PrimaryButton>
                </ButtonContainer>
            </form>
        </AppLayout>
    )
}

export default AddCardScreen
