import React, {ComponentProps} from 'react'
import SelectInput, {SelectOption} from "./SelectInput";
import countries from '../../util/countries.json'

type Props = Omit<ComponentProps<typeof SelectInput>, 'options'>

export const CountryOptions: SelectOption[] = Object.entries(countries).map(([key, value]) => ({
    label: value,
    value: key
}))

const CountrySelect = React.forwardRef<HTMLSelectElement, Props>((props, ref) => {

    return (
        <SelectInput {...props} options={CountryOptions} ref={ref}/>
    )
})

export default CountrySelect
