import React from 'react'
import {Duration as DurationType} from "date-fns";
import {useTranslation} from "react-i18next";

interface Props {
    duration: DurationType
}

const Duration = ({ duration }: Props) => {
    const { t, i18n } = useTranslation()

    const timeSpend = []
    if(duration.years) timeSpend.push(`${duration.years} ${t('years')}`)
    if(duration.months) timeSpend.push(`${duration.months} ${t('months')}`)
    if(duration.weeks) timeSpend.push(`${duration.weeks} ${t('weeks')}`)
    if(duration.days) timeSpend.push(`${duration.days} ${t('days')}`)
    if(duration.hours) timeSpend.push(`${duration.hours} ${t('hours')}`)
    if(duration.minutes) timeSpend.push(`${duration.minutes} ${t('minutes')}`)
    if(duration.seconds) timeSpend.push(`${duration.seconds} ${t('seconds')}`)

    return (
        <>{timeSpend.join(' ')}</>
    )
}

export default Duration
