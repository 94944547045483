const routes = {
    base: '/',
    signin: "/sign-in",
    signup: "/sign-up",
    verify: "/verify/:verifyToken/:email",
    cardHolders: {
      base: '/cardholder',
      create: 'create',
      details: 'holderId'
    },
    chargeStations: {
        base: '/chargers',
        create: 'create',
        details: ':stationId'
    },
    customers: {
        base: '/customers',
        create: 'create',
        details: ':clientId'
    },
    locations: {
        base: '/locations',
        create: "create",
        details: ":locationId",
        edit: ":locationId/edit"
    },
    onboarding: {
        base: "/onboarding",
        customer: "customer",
        subscription: "subscription",
        payment: "payment",
        complete: "complete"
    },
    cards: {
        base: '/cards',
        add: 'add',
        details: ':cardId'
    },
    rateplan: {
        base: '/rate',
        create: 'add',
        details: ':planId'
    },
    transactions: {
        base: '/transactions',
        details: ':chargeSessionId'
    },
    settings: {
        base: '/settings'
    }
}

export default routes
