import {Route, Routes} from "react-router-dom";
import routes from "./routes";
import SigninScreen from "../Screens/Auth/SigninScreen";
import DashboardScreen from "../Screens/DashboardScreen";
import RequireAuth from "./RequireAuth";
import SignupScreen from "../Screens/Auth/SignupScreen";
import UserVerifyScreen from "../Screens/Auth/UserVerifyScreen";
import CustomerOnboardingScreen from "../Screens/Onboarding/CustomerOnboardingScreen";
import SubscriptionOnboardingScreen from "../Screens/Onboarding/SubscriptionOnboardingScreen";
import PaymentOnboardingScreen from "../Screens/Onboarding/PaymentOnboardingScreen";
import CompleteOnboardingScreen from "../Screens/Onboarding/CompleteOnboardingScreen";
import LocationsScreen from "../Screens/Locations/LocationsScreen";
import CreateLocationScreen from "../Screens/Locations/CreateLocationScreen";
import LocationDetailsScreen from "../Screens/Locations/LocationDetailsScreen";
import ChargeStationsScreen from "../Screens/ChargeStations/ChargeStationsScreen";
import CreateChargeStationScreen from "../Screens/ChargeStations/CreateChargeStationScreen";
import ChargeStationDetailsScreen from "../Screens/ChargeStations/ChargeStationDetailsScreen";
import ClientsScreen from "../Screens/Clients/ClientsScreen";
import CreateClientScreen from "../Screens/Clients/CreateClientScreen";
import ClientDetailsScreen from "../Screens/Clients/ClientDetailsScreen";
import AddCardScreen from "../Screens/Cards/AddCardScreen";
import CardDetailsScreen from "../Screens/Cards/CardDetailsScreen";
import CardsScreen from "../Screens/Cards/CardsScreen";
import TransactionsScreen from "../Screens/Transactions/TransactionsScreen";
import TransactionDetailsScreen from "../Screens/Transactions/TransactionDetailsScreen";
import RatePlanScreen from "../Screens/RatePlan/RatePlanScreen";
import CreateRatePlanScreen from "../Screens/RatePlan/CreateRatePlanScreen";
import EditLocationScreen from "../Screens/Locations/EditLocationScreen";
import RatePlanDetailsScreen from "../Screens/RatePlan/RatePlanDetailsScreen";
import CardHoldersScreen from "../Screens/CardHolders/CardHoldersScreen";
import CreateCardHolderScreen from "../Screens/CardHolders/CreateCardHolderScreen";

const Router = () => {
    return (
        <Routes>
            <Route path='/' element={<RequireAuth><DashboardScreen /></RequireAuth>}/>
            <Route path={routes.signin} element={<SigninScreen />} />
            <Route path={routes.signup} element={<SignupScreen />} />
            <Route path={routes.verify} element={<UserVerifyScreen />} />
            <Route path={routes.onboarding.base} >
                <Route path={routes.onboarding.customer} element={<RequireAuth><CustomerOnboardingScreen /></RequireAuth>} />
                <Route path={routes.onboarding.subscription} element={<RequireAuth><SubscriptionOnboardingScreen /></RequireAuth>} />
                <Route path={routes.onboarding.payment} element={<RequireAuth><PaymentOnboardingScreen /></RequireAuth>} />
                <Route path={routes.onboarding.complete} element={<RequireAuth><CompleteOnboardingScreen /></RequireAuth>} />
            </Route>
            <Route path={routes.locations.base} element={<RequireAuth><LocationsScreen /></RequireAuth>} />
            <Route path={`${routes.locations.base}/${routes.locations.create}`} element={<RequireAuth><CreateLocationScreen /></RequireAuth>} />
            <Route path={`${routes.locations.base}/${routes.locations.details}`} element={<RequireAuth><LocationDetailsScreen /></RequireAuth>} />
            <Route path={`${routes.locations.base}/${routes.locations.edit}`} element={<RequireAuth><EditLocationScreen /></RequireAuth>} />

            <Route path={routes.cardHolders.base} element={<RequireAuth><CardHoldersScreen /></RequireAuth>} />
            <Route path={`${routes.cardHolders.base}/${routes.cardHolders.create}`} element={<RequireAuth><CreateCardHolderScreen /></RequireAuth>}/>

            <Route path={routes.chargeStations.base} element={<RequireAuth><ChargeStationsScreen /></RequireAuth>} />
            <Route path={`${routes.chargeStations.base}/${routes.chargeStations.create}`} element={<RequireAuth><CreateChargeStationScreen/></RequireAuth>} />
            <Route path={`${routes.chargeStations.base}/${routes.chargeStations.details}`} element={<RequireAuth><ChargeStationDetailsScreen/></RequireAuth>} />

            <Route path={routes.customers.base} element={<RequireAuth><ClientsScreen /></RequireAuth>} />
            <Route path={`${routes.customers.base}/${routes.customers.create}`} element={<RequireAuth><CreateClientScreen /></RequireAuth>} />
            <Route path={`${routes.customers.base}/${routes.customers.details}`} element={<RequireAuth><ClientDetailsScreen /></RequireAuth>} />

            <Route path={routes.cards.base} element={<RequireAuth><CardsScreen /></RequireAuth>} />
            <Route path={`${routes.cards.base}/${routes.cards.add}`} element={<RequireAuth><AddCardScreen /></RequireAuth>} />
            <Route path={`${routes.cards.base}/${routes.cards.details}`} element={<RequireAuth><CardDetailsScreen/></RequireAuth>} />

            <Route path={routes.transactions.base} element={<RequireAuth><TransactionsScreen /></RequireAuth>} />
            <Route path={`${routes.transactions.base}/${routes.transactions.details}`} element={<RequireAuth><TransactionDetailsScreen /></RequireAuth>} />

            <Route path={routes.rateplan.base} element={<RequireAuth><RatePlanScreen /></RequireAuth>} />
            <Route path={`${routes.rateplan.base}/${routes.rateplan.create}`} element={<RequireAuth><CreateRatePlanScreen /></RequireAuth>} />
            <Route path={`${routes.rateplan.base}/${routes.rateplan.details}`} element={<RequireAuth><RatePlanDetailsScreen /></RequireAuth>} />
        </Routes>
    )
}

export default Router
