import React from "react"
import AppLayout from "../Layouts/AppLayout";


export default () => {

    return (
        <AppLayout>
            404
        </AppLayout>
    )
}