import React from 'react'
import FullScreenLayout from "../../Layouts/FullScreenLayout";
import {gql, useQuery} from "@apollo/client";
import {Elements} from '@stripe/react-stripe-js';
import Logo from "../../Components/App/Logo";
import {OnboardingModal, OnboardingWrapper} from "./CustomerOnboardingScreen";
import styled from "@emotion/styled";
import {Trans, useTranslation} from "react-i18next";
import {pxToRem} from "../../theme/typograhpy";
import {loadStripe} from "@stripe/stripe-js";
import OnboardingPaymentForm from "./OnboardingPaymentForm";

const stripePromise = loadStripe('pk_test_51HD2TdJMW1VoM89zL0PxGmQnstbFCVtq0lucSbATz3Scg865O2c0gBYyYSmWxoEr74Lifuc4ZOIse2iZUYb2Jd7E00Ow6iaBlb');

const Title = styled.h1`
    font-family: ${({ theme }) => theme.typography.H1.fontFamily};
    font-weight: ${({ theme }) => theme.typography.H1.fontWeight};
    color: ${({ theme }) => theme.typography.H1.color};
    font-size: ${pxToRem(32)}rem;
    line-height: ${pxToRem(36)}rem;
  text-align: center;
`

const Intro = styled.p`
  font-size: ${pxToRem(16)}rem;
  margin-bottom: 30px;
`

const StyledOnboardingModal = styled(OnboardingModal)`
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  height: fit-content;
  max-height: 100%;
  min-height: 80%;
`

const SETUP_INTENT_QUERY = gql`
    query FetchStripeSetupIntent {
        fetchStripeSetupIntent {
            secret
        }
    }
`

//@todo: loading icon
const PaymentOnboardingScreen = () => {
    const { t, i18n } = useTranslation()
    const { loading: queryLoading, error, data: intentData } = useQuery(SETUP_INTENT_QUERY)

    console.log({ intentData })

    const stripeOptions = {
        clientSecret: intentData?.fetchStripeSetupIntent?.secret || ""
    }

    return (
        <FullScreenLayout>

            <OnboardingWrapper>
                <StyledOnboardingModal>
                    <Logo />

                    <Title>{t('Payment details')}</Title>

                    <Intro>
                        <Trans i18nKey={'OnboardingPaymentIntro'}>
                            Setup your payment method for the payment of the monthly subscription. This payment method is not
                            used for recieving payments from your customers. We will setup your account for receiving customer payments later.
                        </Trans>
                    </Intro>

                    {!queryLoading &&
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <OnboardingPaymentForm />
                    </Elements>}
                </StyledOnboardingModal>
            </OnboardingWrapper>

        </FullScreenLayout>
    )
}

export default PaymentOnboardingScreen
