import React, {InputHTMLAttributes} from "react";
import styled from "@emotion/styled";
import {pxToRem} from "../../theme/typograhpy";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  
  &:first-child {
    margin-top: 0;
  }
`

const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
`

const StyledInput = styled('input', {
    shouldForwardProp: (prop) => prop !== 'hasLabel'
})<{hasLabel?: boolean}>`
    margin-top: ${({ hasLabel }) => hasLabel ? 0 : '10px'};
    margin-left: ${({ hasLabel }) => hasLabel ? '10px' : 0};
`

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
}

const CheckboxInput = React.forwardRef<HTMLInputElement, Props>(({label, ...props}, ref) => {
    return (
        <Wrapper>
            <Label>
                {label}
                <StyledInput className='uk-checkbox' type='checkbox' {...props} ref={ref} hasLabel={Boolean(label)} />
            </Label>
        </Wrapper>
    )
})

export default CheckboxInput
