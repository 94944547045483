import React, {ReactNode} from 'react'
import styled from "@emotion/styled";
import H1 from "./H1";
import {pxToRem} from "../../theme/typograhpy";

const StyledH1 = styled(H1)`
  font-size: ${pxToRem(36)}rem;
  line-height: 110%;
`

type PageTitleProps = {
    children: ReactNode
}

const PageTitle = ({ children, ...props}: PageTitleProps) => {

    return (
        <StyledH1 {...props}>
            {children}
        </StyledH1>
    )
}

export default PageTitle
