import React, {useEffect, useRef, useState} from 'react'
import AppLayout from "../../Layouts/AppLayout";
import {useTranslation} from "react-i18next";
import PageHeader from "../../Components/Headings/PageHeader";
import {Card, CardBody, Grid} from "uikit-react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {LocationsQuery} from "../Locations/LocationsScreen";
import SelectInput from "../../Components/Forms/SelectInput";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import styled from "@emotion/styled";
import {useNavigate} from "react-router";
import routes from "../../Router/routes";
import TextInput from "../../Components/Forms/TextInput";
import {useForm} from "react-hook-form";

const NextButton = styled(PrimaryButton)`
  margin-top: 15px;
  width: 100%;
  text-align: right;
`

const createChargeStation = gql`
    mutation CreateChargeStation(
        $name: String
        $locationId: String
    ) {
        createChargepoint(input: {
            locationId: $locationId
            name: $name
        }) {
            id
            name
            identifier
        }
    }
`

type FormData = {
    name: string
    locationId: string
}

const CreateChargeStationScreen = () => {
    const { t, i18n } = useTranslation()
    const { register, handleSubmit, watch, formState: { errors } } = useForm<FormData>();
    const { loading: locationsLoading, data: locationsData } = useQuery(LocationsQuery)
    const [ create, { data: chargeStationData }] = useMutation(createChargeStation)
    const locationSelection = useRef<HTMLSelectElement | null>(null)
    const navigate = useNavigate()

    //@ts-ignore
    const locations = locationsData?.locations?.map(location => {
        const label = []
        label.push(location.name)
        label.push(location.address)
        label.push(location.city)
        label.push(location.countr)

        return {
            value: location.id,
            label: label.filter(v => v !== null && v !== undefined).join(', ')
        }
    }) || []

    useEffect(() => {
        if(chargeStationData?.createChargepoint?.id) navigate(`${routes.chargeStations.base}/${chargeStationData.createChargepoint.id}`)
    }, [chargeStationData?.createChargepoint])

    const next = (data: FormData) => {
        if(data.locationId) {
            create({
                variables: {
                    name: data.name,
                    locationId: data.locationId
                }
            })
        }
    }

    return (
        <AppLayout>
            <PageHeader title={t('Charge stations')} />
            <Card>
                <CardBody>
                    <form onSubmit={handleSubmit(next)}>
                    <TextInput label={t('ChargeStationName')} {...register('name')} />

                    <p>Select the location where the station is placed.</p>
                    {!locationsLoading && locations &&
                        <SelectInput
                            options={locations}
                            label={t('Location')}
                            {...register('locationId', { required: true }) }
                        />
                    }
                    <NextButton type="submit">
                        {t('Next')}
                    </NextButton>
                    </form>
                </CardBody>
            </Card>
        </AppLayout>
    )
}

export default CreateChargeStationScreen
