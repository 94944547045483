import React from 'react'
import AppLayout from "../Layouts/AppLayout";
import {Card, CardBody, CardHeader, Grid, Section} from "uikit-react";
import {gql, useQuery} from "@apollo/client";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import Big from 'big.js'

const STATIONS_COUNT_QUERY = gql`
    query ChargePointsStatusCount {
      chargePointsStatusCount {
        offline
        online
      }
    }
`

const SESSIONS_METRICS_QUERY = gql`
    query GetChargeSessionDashboardMetrics {
      getChargeSessionDashboardMetrics {
        activeNow
        today
        energyToday
      }
    }
`

const StyledHeader = styled(CardHeader)`
  font-weight: 700;
  font-size: 24px;
  padding: 20px;
  color: #F2C611
`

const StyledCardBody = styled(CardBody)`
  padding: 20px;
`

const Metrics = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const MetricLabel = styled.div`
  font-weight: 600;
  font-size: 18px;
`

const MetricValue = styled.div`
  font-size: 18px;
`

const DashboardScreen = () => {
    const { data: csData } = useQuery(STATIONS_COUNT_QUERY)
    const { data: sessionMetrics } = useQuery(SESSIONS_METRICS_QUERY)
    const { t, i18n } = useTranslation()

    const energyToday = Big(sessionMetrics?.getChargeSessionDashboardMetrics?.energyToday || 0).div(1000).round(3).toString()
    return (
        <AppLayout>

            <Grid>
                <Section width='1-4'>
                    <Card>
                        <StyledHeader>
                            {t('ConnectedStationsHeader')}
                        </StyledHeader>
                        <StyledCardBody>
                            <Metrics>
                                <Metric>
                                    <MetricLabel>
                                        {t('MetricOnlineLabel')}
                                    </MetricLabel>
                                    <MetricValue>
                                        {csData?.chargePointsStatusCount?.online}
                                    </MetricValue>
                                </Metric>
                                <Metric>
                                    <MetricLabel>
                                        {t('MetricOfflineLabel')}
                                    </MetricLabel>
                                    <MetricValue>
                                        {csData?.chargePointsStatusCount?.offline}
                                    </MetricValue>
                                </Metric>
                            </Metrics>
                        </StyledCardBody>
                    </Card>
                </Section>

                <Section width='1-4'>
                    <Card>
                        <StyledHeader>
                            {t('ActiveSessionsHeader')}
                        </StyledHeader>
                        <StyledCardBody>
                            <Metrics>
                                <Metric>
                                    <MetricLabel>
                                        {t('SessionsLabel')}
                                    </MetricLabel>
                                    <MetricValue>
                                        {sessionMetrics?.getChargeSessionDashboardMetrics?.activeNow}
                                    </MetricValue>
                                </Metric>
                            </Metrics>
                        </StyledCardBody>
                    </Card>
                </Section>

                <Section width='1-4'>
                    <Card>
                        <StyledHeader>
                            {t('SessionsTodayHeader')}
                        </StyledHeader>
                        <StyledCardBody>
                            <Metrics>
                                <Metric>
                                    <MetricLabel>
                                        {t('SessionsLabel')}
                                    </MetricLabel>
                                    <MetricValue>
                                        {sessionMetrics?.getChargeSessionDashboardMetrics?.today}
                                    </MetricValue>
                                </Metric>

                                <Metric>
                                    <MetricLabel>
                                        {t('EnergyLabel')}
                                    </MetricLabel>
                                    <MetricValue>
                                        {energyToday} kWh
                                    </MetricValue>
                                </Metric>
                            </Metrics>
                        </StyledCardBody>
                    </Card>
                </Section>
            </Grid>
        </AppLayout>
    )
}

export default DashboardScreen
