import React from 'react';
import ReactDOM from 'react-dom';
import 'uikit'
import 'uikit/dist/css/uikit.min.css'
import './index.css';
import App from './App';
import { ThemeProvider } from '@emotion/react'
import { BrowserRouter } from "react-router-dom";
import './translations/i18n'
import theme from "./theme";

ReactDOM.render(
  <React.StrictMode>
          <ThemeProvider theme={theme}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
          </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
