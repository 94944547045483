import React from "react";
import styled from "@emotion/styled";
import {pxToRem} from "../../theme/typograhpy";

const StyledButton = styled.button`
    background-color: ${({ theme }) => theme.colors.yellow};
    border: ${({ theme }) => `1px solid ${theme.colors.yellow}`};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${pxToRem(16)}rem;
    font-weight: 600;
    cursor: pointer;
`

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
}

const PrimaryButton = ({ children, className, ...props }: Props) => {

    return (
        <div className={className}>
            <StyledButton {...props} className={'uk-button uk-button-default'}>
                {children}
            </StyledButton>
        </div>
    )
}

export default PrimaryButton
