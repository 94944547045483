import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import FullScreenLayout from "../../Layouts/FullScreenLayout";
import {CenterScreenModal} from "../../Components/Modals/CenterScreenModal";
import SignupWindow from "../../Components/Signup/SignupWindow";
import Logo from "../../Components/App/Logo";
import PasswordInput from "../../Components/Forms/PasswordInput";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import TextInput from "../../Components/Forms/TextInput";
import {gql, useMutation} from "@apollo/client";
import {userId, userToken} from "../../App";
import {useNavigate} from "react-router-dom";
import routes from "../../Router/routes";

const FormContainer = styled.div`
  width: 100%;
`

const StyledPasswordInput = styled(PasswordInput)`
  margin-top: 0.5rem;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 1rem;
`

interface SingupProps {
    email: string,
    password: string
}

const SIGNUP_MUTATION = gql`
    mutation SignupMutation($email: String!, $password: String!) {
        createUser(email: $email, password: $password) {
            token,
            id
        }
    }
`

type FormValues = {
    email: string
    password: string
}

//@todo: error messages
const SignupScreen = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();
    const [ loading, setLoading ] = useState(false)
    const { t, i18n } = useTranslation()
    const [ signup, { data: signupData } ] = useMutation(SIGNUP_MUTATION)
    const navigate = useNavigate()

    const handleSignup = async ({ email, password }: SingupProps) => {
        setLoading(true)
        try {
            await signup({
                variables: {
                    email,
                    password
                }
            })
        } catch (err) {
            setLoading(false)
            console.error(err)
        }
    }

    useEffect(() => {
        userId(signupData?.createUser?.id)
        userToken(signupData?.createUser?.token)
        if(signupData?.createUser?.id && signupData?.createUser?.token) {
            navigate(`${routes.onboarding.base}/${routes.onboarding.customer}`)
        }
        if(loading) setLoading(false)
    }, [signupData])

    return (
        <FullScreenLayout>
            <CenterScreenModal>
                <SignupWindow>
                    <>
                        <Logo />

                        <FormContainer>
                            <form onSubmit={handleSubmit(handleSignup)}>
                                <TextInput placeholder={t('E-mail address')} {...register('email', { required: true })} />
                                <StyledPasswordInput placeholder={t('Password')} {...register('password', { required: true })} />

                                <StyledPrimaryButton type={'submit'} disabled={loading}>
                                    {t('Create account')}
                                </StyledPrimaryButton>
                            </form>
                        </FormContainer>
                    </>
                </SignupWindow>
            </CenterScreenModal>
        </FullScreenLayout>
    )
}

export default SignupScreen
