import React from 'react'
import {Link} from "react-router-dom";
import routes from "../../../Router/routes";

interface Props {
    locationId: string
}

const LocationTableActionButtons = ({ locationId, ...props }: Props) => {

    return (
        <div {...props}>
            <Link to={routes.locations.edit.replace(':locationId', locationId)}>Edit</Link>
        </div>
    )
}

export default LocationTableActionButtons
