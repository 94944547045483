import React, {useEffect} from 'react'
import {useStripe} from "@stripe/react-stripe-js";
import {useNavigate} from "react-router-dom";
import routes from "../../Router/routes";
import {RingLoader} from "react-spinners";
import {useTheme} from "@emotion/react";
import {gql, useMutation} from "@apollo/client";

//@todo: set alert message with status

const FINISH_PAYMENT_MUTATION = gql`
    mutation FinishPayment($intentId: String!) {
        finishPaymentSetupIntent(intentId: $intentId)
    }
`

const PaymentStatus = () => {
    const stripe = useStripe();
    const navigate = useNavigate()
    const theme = useTheme()
    const [ finishPayment, { data }] = useMutation(FINISH_PAYMENT_MUTATION)

    useEffect(() => {
        if(!stripe) return

        const clientSecret = new URLSearchParams(window.location.search).get(
            'setup_intent_client_secret'
        );

        if(!clientSecret) return

        stripe
            .retrieveSetupIntent(clientSecret)
            .then(({setupIntent}) => {
                console.log(setupIntent)

                if(!setupIntent) {
                    navigate(`${routes.onboarding.base}/${routes.onboarding.payment}`)
                    return
                }

                switch (setupIntent.status) {
                    case 'succeeded' || 'processing':
                        finishPayment({
                            variables: {
                                intentId: setupIntent.id
                            }
                        }).then(() => {
                            navigate('/')
                        })
                        break;
                    case 'requires_payment_method':
                        // navigate(`${routes.onboarding.base}/${routes.onboarding.payment}`)
                        break;
                }
            })

    }, [stripe])

    return (
        <RingLoader loading={true} color={theme.colors.yellow} />
    )
}

export default PaymentStatus
