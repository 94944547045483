import React, {InputHTMLAttributes} from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`

const Label = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

export type SelectOption = {
    value: string
    label: string
}

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
    label?: string
    options: SelectOption[]
}

const SelectInput = React.forwardRef<HTMLSelectElement, Props>(({label, options, ...props}, ref) => {
    return (
        <Wrapper>
            {label && <Label>{label}</Label>}
            <select className={'uk-select'} {...props} ref={ref}>
                {options?.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
        </Wrapper>
    )
})

export default SelectInput
