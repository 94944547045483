import React, {useEffect, useState} from 'react';
import FullScreenLayout from "../../Layouts/FullScreenLayout";
import {CenterScreenModal} from "../../Components/Modals/CenterScreenModal";
import styled from "@emotion/styled";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import TextInput from "../../Components/Forms/TextInput";
import {useForm} from "react-hook-form";
import PasswordInput from "../../Components/Forms/PasswordInput";
import {Link, useNavigate} from "react-router-dom";
import {pxToRem} from "../../theme/typograhpy";
import {gql, useMutation} from "@apollo/client";
import {customerId, userId, userToken} from "../../App";
import {useTranslation} from "react-i18next";
import SignupWindow from "../../Components/Signup/SignupWindow";
import Logo from "../../Components/App/Logo";

const FormContainer = styled.div`
  width: 100%;
  margin-top: 15px;
`

const StyledPasswordInput = styled(PasswordInput)`
  margin-top: 0.5rem;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 1rem;
  width: 100%;
  text-align: center;
`

const ForgotPasswordLink = styled(Link)`
  font-size: ${pxToRem(14)}rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grayLight};
`

const SIGNIN_MUTATION = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            userId
            customerId
            token
        }
    }
`

interface handleLoginProps {
    email: string
    password: string
}

type FormValues = {
    email: string
    password: string
}

//@todo: add text to translations
const SigninScreen = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();
    const [ login, { data: loginData }] = useMutation(SIGNIN_MUTATION, {

    })
    const [ loading, setLoading ] = useState(false)
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const handleLogin = async ({ email, password }: handleLoginProps) => {
        setLoading(true)
        try {
            await login({variables: {email, password}})
        } catch(err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(false)
        if(loginData?.login?.userId && loginData?.login?.token) {

            localStorage.setItem("userId", loginData?.login?.userId)
            localStorage.setItem("userToken", loginData?.login?.token)
            localStorage.setItem("customerId", loginData?.login?.customerId)

            userId(loginData?.login?.userId)
            userToken(loginData?.login?.token)
            customerId(loginData?.login?.customerId)
            navigate("/")
        }
    }, [loginData])

    //@todo: add a nice spinner on submit
    //@todo: show erorr messages
    return (
        <FullScreenLayout>
            <CenterScreenModal>
                <SignupWindow>
                    <>
                        <Logo />

                        <FormContainer>
                        <form onSubmit={handleSubmit(handleLogin)}>

                            <TextInput placeholder={'E-mail address'} {...register('email', { required: true })} />
                            <StyledPasswordInput placeholder='Password' {...register('password', { required: true })} />


                            <ForgotPasswordLink to='/forgot-password'>Forgot password?</ForgotPasswordLink>
                            <StyledPrimaryButton type='submit' disabled={loading}>
                                {t('Sign-in')}
                            </StyledPrimaryButton>
                        </form>
                        </FormContainer>
                    </>
                </SignupWindow>
            </CenterScreenModal>
        </FullScreenLayout>
    )
}

export default SigninScreen
