import React, {useMemo} from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import { Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {Card, CardBody} from 'uikit-react'
import routes from "../../Router/routes";
import {gql, useQuery} from "@apollo/client";
import Table from "../../Components/Table/Table";
import LocationTableActionButtons from "./Components/LocationTableActionButtons";
import LoadingSpinner from "../../Components/App/LoadingSpinner";

export const LocationsQuery = gql`
    query GetLocations {
        locations {
            id
            name
            address
            address1
            postalCode
            province
            city
            country
            chargePoints {
              id
            }
        }
    }
`

const LocationsScreen = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const {loading: queryLoading, error, data } = useQuery(LocationsQuery)

    const columns = useMemo(()=> [
        {
            Header: t('Location'),
            accessor: 'location'
        },
        {
            Header: t('City'),
            accessor: 'city'
        },
        {
            Header: t('ChargeStations'),
            accessor: 'chargepoints'
        },
        {
            Header: 'Actions',
            accessor: 'actions'
        }
    ], [t])

    //@ts-ignore
    const mappedLocations = data?.locations?.map(location => ({
        location: <Link to={`${routes.locations.base}/${location.id}`}>{[location.name, location.address].join(', ')}</Link>,
        city: location.city,
        chargepoints: location.chargePoints?.length || 0,
        actions: <LocationTableActionButtons locationId={location.id} />
    })) || []

    return (
        <AppLayout heading={
            <PageHeader title={t('Locations')}>
                <PrimaryButton onClick={() => navigate(`${routes.locations.base}/${routes.locations.create}`)}>
                    {t('Add location')}
                </PrimaryButton>
            </PageHeader>
        }>
            {queryLoading && <LoadingSpinner />}
            {!queryLoading &&
                <Card>
                    <CardBody>
                        {mappedLocations.length > 0 &&
                            <Table columns={columns} data={mappedLocations} size='fullwidth' hoverable={true}/>
                        }
                    </CardBody>
                </Card>
            }
        </AppLayout>
    )
}

export default LocationsScreen
