import React, {useEffect, useRef} from 'react'
// @ts-ignore
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { hexToCSSFilter } from 'hex-to-css-filter';
import './Marker.css'
import {useTheme} from "@emotion/react";

mapboxgl.accessToken = 'pk.eyJ1Ijoic2ViYXN0aWFhbi1pb25pemVkIiwiYSI6ImNrdzM1bXZ2dDFhY2QydXJvMXM5MXpiOW4ifQ.iO1fD2n9msZd24Yz5lnkgQ';

type Marker = {
    lat: string
    long: string
}

interface MapProps {
    lat: string
    long: string
    style?: string
    options?: any //@todo: mapbox type available?
    zoom: number
    markers?: Marker[]
}

const Map = ({ lat, long, options, style, zoom, markers, ...props }: MapProps) => {
    const mapContainer = useRef()
    const map = useRef()
    const theme = useTheme()

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: style || 'mapbox://styles/sebastiaan-ionized/cl48d9vp5000n16ms1rwzyd8w',
            center: [long, lat],
            zoom: zoom,
            ...options
        });
    }, [lat, long, options, style, zoom])

    useEffect(() => {
        if(map.current && markers && markers.length > 0) {
            const markerColor = hexToCSSFilter(theme.colors.primary)
            console.log({markerColor})
            for(const marker of markers) {
                const element = document.createElement("div")
                element.setAttribute("class", "marker-position")
                element.setAttribute("style", `filter: ${markerColor.filter};`)
                new mapboxgl
                    .Marker({
                        element
                    })
                    .setLngLat([marker.long, marker.lat])
                    .addTo(map.current)
            }
        }
    }, [ markers ])

    return (
        // @ts-ignore
        <div ref={mapContainer} {...props} />
    )
}

export default Map
