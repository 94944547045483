import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './en/translation.json';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en
        }
    },
    lng: "en", //@todo: implement language detection
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    }
})

export default i18n
