import React from 'react'
import {Card, CardBody, Grid, Section} from "uikit-react";
import TextInput from "../../../Components/Forms/TextInput";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton";
import TextareaInput from "../../../Components/Forms/TextareaInput";
import SelectInput, {SelectOption} from "../../../Components/Forms/SelectInput";
import Label from "../../../Components/Typography/Label";
import CheckboxInput from "../../../Components/Forms/CheckBoxInput";
import {LocationAccessOptions, LocationLocatedAtOptions} from "../CreateLocationScreen";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {Location} from "../../../generated/graphql";

//@todo: remove any
interface LocationFormProps {
    onSubmit: () => void
    existingData?: Location
}

const ButtonContainer = styled.div`
  margin-top: 15px;
  text-align: right;
`

type FormValues = {
    name?: string
    address: string
    address1?: string
    postalCode?: string
    city: string
    province?: string
    directions?: string
    access: SelectOption
    location: SelectOption
    parking_ev_only: boolean
    parking_plugged: boolean
    parking_disabled: boolean
    parking_customers: boolean
    parking_motorcycles: boolean
    parking_car_share: boolean
}

const LocationForm = ({ onSubmit, existingData }: LocationFormProps) => {
    const { t, i18n } = useTranslation()
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>()

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid gutter='small'>
                <Section width='expand'>
                    <Card>
                        <CardBody>
                            <TextInput label={t('Name')} defaultValue={existingData?.name || ""} {...register('name', { required: false })}/>
                            <TextInput label={t('Address')} defaultValue={existingData?.address || ""} {...register('address', { required: true })}/>
                            <TextInput defaultValue={existingData?.address1 || ""}  {...register('address1', { required: false })}/>

                            <Grid gutter='small'>
                                <Section width='1-3@m'><TextInput label={t('Postal code')} defaultValue={existingData?.postalCode || ""} {...register('postalCode', { required: false })}/></Section>
                                <Section width='expand'><TextInput label={t('City')} defaultValue={existingData?.city || ""} {...register('city', { required: true })}/></Section>
                            </Grid>

                            <TextInput label={t('Province / state')} defaultValue={existingData?.province || ""} {...register('province', { required: false })}/>
                        </CardBody>
                    </Card>
                </Section>
                {/*<Section width='1-3@m'>*/}
                {/*    <Card>*/}
                {/*        <CardBody>*/}
                {/*            Map*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</Section>*/}
            </Grid>

            <Grid gutter='small'>
                <Section width='expand'>
                    <Card>
                        <CardBody>
                            <Grid gutter='small'>
                                <Section width='1-2'>
                                    <TextareaInput label='Directions' defaultValue={existingData?.directions || ""} {...register('directions', { required: false })} rows={7} />
                                </Section>
                                <Section width='1-2'>
                                    <SelectInput options={LocationAccessOptions} defaultValue={existingData?.access || 'PUBLIC'} label={'Access'} {...register('access', { required: true })} />
                                    <SelectInput options={LocationLocatedAtOptions} defaultValue={existingData?.located || 'PUBLIC'} label={'Location'} {...register('location', { required: true })} />
                                    <Label>Parking restrictions</Label>
                                    <Grid gutter='small'>
                                        <Section width='auto'><CheckboxInput {...register('parking_ev_only')} label='EV Only' /></Section>
                                        <Section width='auto'><CheckboxInput {...register('parking_plugged')} label='While plugged-in' /></Section>
                                        <Section width='auto'><CheckboxInput {...register('parking_disabled')} label='Reserved for people with disabilities' /></Section>
                                        <Section width='auto'><CheckboxInput {...register('parking_customers')} label='For customers only' /></Section>
                                        <Section width='auto'><CheckboxInput {...register('parking_motorcycles')} label='Suitable for motorcycles only' /></Section>
                                        <Section width='auto'><CheckboxInput {...register('parking_car_share')} label='Reserved for car-sharing vehicles' /></Section>
                                    </Grid>
                                </Section>
                            </Grid>
                        </CardBody>
                    </Card>
                </Section>
            </Grid>

            <Grid gutter='small'>
                <Section width='expand'>
                    <ButtonContainer>
                        <PrimaryButton type={'submit'}>
                            {t('Save')}
                        </PrimaryButton>
                    </ButtonContainer>
                </Section>
            </Grid>
        </form>
    )
}

export default LocationForm
