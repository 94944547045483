import React, {useState} from 'react'
import styled from "@emotion/styled";
import FullScreenLayout from "../../Layouts/FullScreenLayout";
import {OnboardingModal, OnboardingWrapper} from "./CustomerOnboardingScreen";
import Logo from "../../Components/App/Logo";
import {pxToRem} from "../../theme/typograhpy";
import {Trans, useTranslation} from "react-i18next";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {CheckMarkIcon} from "../../Components/Icons";
import {gql, useMutation, useReactiveVar} from "@apollo/client";
import {customerId} from "../../App";
import {useNavigate} from "react-router-dom";
import routes from "../../Router/routes";

const StyledOnboardingModal = styled(OnboardingModal)`
  padding: 15px;
  box-sizing: border-box;
  background-color: #FFFFFF;
`

const SubscriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin: 30px 0 0 15px;
`

const SubscriptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const SubscriptionHeader = styled.div`
  text-align: center;
`

const SubscriptionName = styled.h2`
  font-family: ${({ theme }) => theme.typography.H2.fontFamily};
  font-weight: ${({ theme }) => theme.typography.H2.fontWeight};
  color: ${({ theme }) => theme.typography.H2.color};
  font-size:  ${pxToRem(50)}rem;
  line-height: ${pxToRem(25)}rem;
`

const SubscriptionPrice = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin: 10px 0;
`

const SubscriptionDescription = styled.div`
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
`

const FeatureTable = styled.div`
  width: 100%;
  grid-column-start: 1;
  grid-column-end: span 2;
  font-weight: 400;
  text-align: center;
  font-size: ${pxToRem(18)}rem;

  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 5px;
  }
`

const FeatureItem = styled.div`
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
`

const FeatureCheckMarkIcon = styled(CheckMarkIcon)`
  color: ${({ theme }) => theme.colors.yellow};
  margin-right: 5px;
`

const SubscribeButton = styled(PrimaryButton)`
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
`

const SubscriptionFooter = styled.div`
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
  margin-top: 30px;
`

const SET_SUBSCRIPTION_MUTATION = gql`
    mutation SetSubscription($subscriptionType: String!) {
        setSubscriptionType(subscriptionType: $subscriptionType) {
            subscriptionType
        }
    }
`

const SubscriptionOnboardingScreen = () => {
    const { t, i18n } = useTranslation()
    const [ loading, setLoading ] = useState(false)
    const [ saveSubscription ] = useMutation(SET_SUBSCRIPTION_MUTATION)
    const customer = useReactiveVar(customerId)
    const navigate = useNavigate()
    const baseTransactionCost = "0,10"
    const proTransactionCost = "0,03"

    const setSubscription = async (type: string) => {
        setLoading(true)
        try {
            await saveSubscription({
                variables: {
                    subscriptionType: type
                }
            })
            navigate(`${routes.onboarding.base}/${routes.onboarding.payment}`)
        } catch(err) {
            setLoading(false)
            console.error(err)
        }
    }

    return (
        <FullScreenLayout>

            <OnboardingWrapper>
                <StyledOnboardingModal>
                    <Logo />

                    <SubscriptionWrapper>

                        <SubscriptionColumn>

                            <SubscriptionHeader>
                                <SubscriptionName>Base</SubscriptionName>

                                <SubscriptionPrice>
                                    €0,05 / kWh
                                </SubscriptionPrice>

                                <SubscriptionDescription>
                                    <Trans i18nKey={'BaseSubscriptionDescription'}>
                                        Only pay when your charge points are used. For when you have
                                        limited or irregular use.
                                    </Trans>
                                </SubscriptionDescription>
                            </SubscriptionHeader>

                        </SubscriptionColumn>

                        <SubscriptionColumn>

                            <SubscriptionHeader>
                                <SubscriptionName>Pro</SubscriptionName>
                                <SubscriptionPrice>
                                    €9,- / charge point
                                </SubscriptionPrice>

                                <SubscriptionDescription>
                                    <Trans i18nKey={'ProSubscriptionDescription'}>
                                        Pay a fixed monthly fee per connected charge point.
                                    </Trans>
                                </SubscriptionDescription>
                            </SubscriptionHeader>

                        </SubscriptionColumn>

                        <FeatureTable>
                            <ul>
                                <li>
                                    <FeatureCheckMarkIcon />
                                    {t('Unlimited chargers')}
                                </li>
                                <li>
                                    <FeatureCheckMarkIcon />
                                    {t('Unlimited charging')}
                                </li>
                                <li>
                                    <FeatureCheckMarkIcon />
                                    {t('Unlimited customers')}
                                </li>
                                <li>
                                    <FeatureCheckMarkIcon />
                                    {t('Unlimited charge cards')}
                                </li>
                            </ul>
                        </FeatureTable>
                        <SubscriptionColumn>
                            <FeatureItem>
                                <strong>{t('Transaction costs')}</strong><br />
                                € {baseTransactionCost}
                                <Trans i18nKey={'TransactionRate'}>
                                     / transaction + payment fees
                                </Trans>
                            </FeatureItem>
                        </SubscriptionColumn>

                        <SubscriptionColumn>
                            <FeatureItem>
                                <strong>{t('Transaction costs')}</strong><br />
                                € {proTransactionCost}
                                <Trans i18nKey={'TransactionRate'}>
                                     / transaction + payment fees
                                </Trans>
                            </FeatureItem>
                        </SubscriptionColumn>

                        <SubscriptionColumn>
                            <SubscribeButton disabled={loading} onClick={() => setSubscription('BASE')}>
                                {t('Subscribe')}
                            </SubscribeButton>
                        </SubscriptionColumn>

                        <SubscriptionColumn>
                            <SubscribeButton disabled={loading} onClick={() => setSubscription('PRO')}>
                                {t('Subscribe')}
                            </SubscribeButton>
                        </SubscriptionColumn>

                    </SubscriptionWrapper>

                    <SubscriptionFooter>
                        <p>
                            <Trans i18nKey={'SubscriptionTerms'}>
                                By subscribing you accept our Terms of Service and Privacy Policy.
                            </Trans>
                        </p>
                        <p>
                            <Trans i18nKey={'SubscriptionFooter'}>
                                All prices are in Euro excluding VAT. Pro plan customers are billed a fixed fee per month
                                per connected charger. Base plan customers are billed for the amount of kWh consumed by all
                                connected chargers combined in that month.
                            </Trans>
                        </p>
                    </SubscriptionFooter>
                </StyledOnboardingModal>
            </OnboardingWrapper>

        </FullScreenLayout>
    )
}

export default SubscriptionOnboardingScreen
