import React from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import {useTranslation} from "react-i18next";
import routes from "../../Router/routes";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {Link, useNavigate} from "react-router-dom";
import {Card, CardBody} from "uikit-react";
import {gql, useQuery} from "@apollo/client";
import Table from "../../Components/Table/Table";

const getClientsQuery = gql`
    query GetClients {
        clients {
            id
            name
            billingAddress {
                address1
                address2
                postalCode
                city
                state
                country
            }
            type
        }
    }
`

const ClientsScreen = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const { loading, data } = useQuery(getClientsQuery)
console.log({data})
    const clientColumns = [
        {
            Header: t('Name'),
            accessor: 'name'
        },
        {
            Header: t('Address'),
            accessor: 'address'
        },
        {
            Header: t('Type'),
            accessor: 'type'
        }
    ]

    //@ts-ignore
    const clients = data?.clients?.map(client => {
        const address = []
        address.push(client.billingAddress?.address)
        address.push(client.billingAddress?.city)
        address.push(client.billingAddress?.state)
        address.push(client.billingAddress?.country)

        return {
            name: <Link to={`${routes.customers.base}/${client.id}`}>{client.name}</Link>,
            address: address.filter(a => a !== undefined).join(', '),
            type: client.type
        }
    }) || []

    return (
        <AppLayout heading={
            <PageHeader title={t('Customers')}>
                <PrimaryButton onClick={() => navigate(`${routes.customers.base}/${routes.customers.create}`)}>
                    {t('Add customer')}
                </PrimaryButton>
            </PageHeader>
        }>
            <Card>
                <CardBody>
                    {!loading && clients && clients.length > 0 &&
                        <Table columns={clientColumns} data={clients} size='fullwidth' hoverable={true} />
                    }
                    {!loading && (!clients || clients.length === 0) &&
                        <p>{t('NoCustomers')}</p>
                    }
                </CardBody>
            </Card>
        </AppLayout>
    )
}

export default ClientsScreen
