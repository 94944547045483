import React from 'react'
import AppLayout from "../../Layouts/AppLayout";
import {useParams} from "react-router";
import {gql, useQuery} from "@apollo/client";
import {Card, CardBody, Grid, Section} from "uikit-react";
import PageHeader from "../../Components/Headings/PageHeader";
import {useTranslation} from "react-i18next";
import Label from "../../Components/Typography/Label";
import CardTitle from "../../Components/Typography/CardTitle";
import Table from "../../Components/Table/Table";
import {Link} from "react-router-dom";
import routes from "../../Router/routes";
import TransactionTable from "../../Containers/Transaction/TransactionTable";

const cardDetailsQuery = gql`
    query GetChargeCard($chargeCardId: ID!) {
      getChargeCard(chargeCardId: $chargeCardId) {
        active
        cardHolder {
          firstName
          lastName
          id
          client {
            id
            name
          }
        }
        cardNumber
        id
        idTag
        sessions {
          id
          started
          stopped
          status
          costs {
            currency
            total
          }
          energyConsumed
          timeSpend
          transactionId
          chargePoint {
            location {
              name
              id
              city
            }
          }
        }
      }
    }
`

const CardDetailsScreen = () => {
    const { t, i18n } = useTranslation()
    const { cardId } = useParams()
    const { loading, data } = useQuery(cardDetailsQuery, {
        variables: {
            chargeCardId: cardId
        }
    })

    console.log(cardId)
    const card  = data?.getChargeCard || {}

    console.log(data)
    console.log(card)

    return (
        <AppLayout heading={
            <PageHeader title={t('Card details')} />
        }>
            <Grid gutter='small'>
                <Section width='1-2'>
                    <Card size='small'>
                        <CardBody>
                            <CardTitle>{t('Card details')}</CardTitle>
                            <Grid gutter='small'>
                                <Section width='1-2'>
                                    <Label>{t('Card Id')}</Label>
                                    <p>{card.idTag}</p>

                                    <Label>{t('Card number')}</Label>
                                    <p>{card.cardNumber}</p>
                                </Section>
                                <Section width='1-2'>
                                    <Label>{t('Status')}</Label>
                                    <p>{card.active ? t('Active') : t('Disabled')}</p>

                                    <Label>{t('Card type')}</Label>
                                    <p>{card.type}</p>
                                </Section>
                            </Grid>
                        </CardBody>
                    </Card>
                </Section>

                <Section width='1-2'>
                    <Card size='small'>
                        <CardBody>
                            <CardTitle>{t('Customer')}</CardTitle>
                            <Grid gutter='small'>
                                <Section width='1-2'>
                                    <Label>{t('Customer')}</Label>
                                    <p>
                                        {card.cardHolder?.client?.name &&
                                            <Link to={`${routes.customers.base}/${card.cardHolder?.client?.id}`}>
                                                {card.cardHolder?.client?.name}
                                            </Link>
                                        }
                                        {!card.cardHolder?.client?.name && 'Not assigned'}
                                    </p>
                                </Section>

                                <Section width='1-2'>
                                    <Label>{t('Card holder')}</Label>
                                    {!card.cardHolder?.firstName && !card.cardHolder?.lastName &&
                                        <p>Not assigned</p>
                                    }
                                    {(card.cardHolder?.firstName || card.cardHolder?.lastName) &&
                                        <p>
                                        <Link to={`${routes.cardHolders.base}/${card.cardHolder?.id}`}>
                                            {card.cardHolder?.firstName} {card.cardHolder?.lastName}
                                        </Link>
                                        </p>
                                    }
                                </Section>
                            </Grid>
                        </CardBody>
                    </Card>
                </Section>
            </Grid>

            <Grid gutter='small'>
                <Section width='expand'>
                    <Card size='small'>
                        <CardBody>
                            <CardTitle>{t('ChargeSessions')}</CardTitle>
                            <TransactionTable transactions={card?.sessions} />
                        </CardBody>
                    </Card>
                </Section>
            </Grid>
        </AppLayout>
    )
}

export default CardDetailsScreen
