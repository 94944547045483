//@ts-nocheck
import React, {ComponentProps} from 'react'
import {TableOptions, useTable} from "react-table";

// interface TableProps extends ComponentProps<typeof BulmaTable> {
//     columns: TableOptions<object>["columns"]
//     data: TableOptions<object>["data"]
// }

const Table = ({ columns, data, ...props }: TableProps) => {
    const table = useTable({ columns, data })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = table

    if(!data || data.length < 1) return null

    return (
        <table className='uk-table uk-table-divider uk-table-small' {...getTableProps()} {...props}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
               {rows.map(row => {
                     prepareRow(row)
                     return (
                         <tr {...row.getRowProps()}>
                             {row.cells.map(cell => (
                                 <td {...cell.getCellProps()}>
                                     {cell.render('Cell')}
                                 </td>
                             ))}
                         </tr>
                     )
                 })}
            </tbody>
        </table>
    )
}

export default Table
