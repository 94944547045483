import React, {ReactNode} from "react";
import styled from "@emotion/styled";

const Page = styled.div`
  font-family: ${({ theme }) => theme.typography.p.fontFamily};
  font-size: ${({ theme }) => theme.typography.p.fontSize};
  line-height: ${({ theme }) => theme.typography.p.lineHeight};
  color: ${({ theme }) => theme.typography.p.color};
  padding: 0;
  height: 100%;
  width: 100%;
  background-image: url("/media/background-spring.jpg");
  background-size: cover;
  overflow: auto;
`

const PhotoCredits = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 5px;
  right: 5px;
  background-color: rgba(255,255,255, 0.2);
  padding: 5px 10px;
  border-radius: 15px;
  color: #FFF;
`

interface Props {
    children: ReactNode
}

const FullScreenLayout = ({ children }: Props) => {

    return (
        <Page>
            {children}

            <PhotoCredits>
                Photo by Sergey Shmidt on Unsplash
            </PhotoCredits>
        </Page>
    )
}

export default FullScreenLayout
