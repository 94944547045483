import React, {HTMLAttributes, ReactNode} from 'react'
import styled from "@emotion/styled";

const StyledH1 = styled.h2`
    font-family: ${({ theme }) => theme.typography.H1.fontFamily};
    font-weight: ${({ theme }) => theme.typography.H1.fontWeight};
    color: ${({ theme }) => theme.typography.H1.color};
    font-size:  ${({ theme }) => theme.typography.H1.fontSize};
    line-height: ${({ theme }) => theme.typography.H1.lineHeight};
`

type H1Props = HTMLAttributes<HTMLHeadingElement> & {
    children: ReactNode
}

const H1 = ({ children, ...props}: H1Props) => {
    return (
        <StyledH1 {...props}>
            {children}
        </StyledH1>
    )
}

export default H1
