import React, {useMemo} from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import {useTranslation} from "react-i18next";
import {Card, CardBody} from "uikit-react";
import routes from "../../Router/routes";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {useNavigate} from "react-router";
import {gql, useQuery} from "@apollo/client";
import Table from "../../Components/Table/Table";
import {Link} from "react-router-dom";

const getChargeStations = gql`
    query GetChargeStations {
        chargePoints {
            id
            name
            location {
                id
                name
                address
                city
            }
            identifier
            status
            lastSeen
        }
    }
`

const ChargeStationsScreen = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const {loading, data} = useQuery(getChargeStations)

    const tableColumns = useMemo(() => [
        {
            Header: t('StationId'),
            accessor: 'identifier'
        },
        {
            Header: t('ChargeStationName'),
            accessor: 'name'
        },
        {
            Header: t('Location'),
            accessor: 'location'
        },
        {
            Header: t('status'),
            accessor: 'status'
        },
        {
            Header: t('lastSeen'),
            accessor: 'lastSeen'
        },
    ], [])

    //@ts-ignore
    const mappedChargeStations = data?.chargePoints?.map(station => {
        const location = []
        location.push(station.location.name)
        location.push(station.location.address)
        location.push(station.location.city)

        return {
            identifier: <Link to={`${routes.chargeStations.base}/${station.id}`}>{station.identifier}</Link>,
            name: station.name,
            location: location.filter(l => l !== undefined).join(', '),
            status: station.status,
            lastSeen: station.lastSeen || t('never')
        }
    }) || []

    return (
        <AppLayout heading={
            <PageHeader title={t('Charge stations')}>
                <PrimaryButton
                    onClick={() => navigate(`${routes.chargeStations.base}/${routes.chargeStations.create}`)}>
                    {t('Add charge station')}
                </PrimaryButton>
            </PageHeader>
        }>

            <Card>
                <CardBody>
                    {!loading && mappedChargeStations &&
                        <Table columns={tableColumns} data={mappedChargeStations} size='fullwidth' hoverable={true}/>
                    }
                    {!loading && (!mappedChargeStations || mappedChargeStations.length === 0) &&
                        <p>{t('NoChargeStations')}</p>
                    }
                </CardBody>
            </Card>
        </AppLayout>
    )
}

export default ChargeStationsScreen
