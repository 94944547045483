//@ts-nocheck
import React, {useEffect} from 'react'
import {Button, Grid} from "uikit-react";
import SelectInput, {SelectOption} from "../../Components/Forms/SelectInput";
import {useTranslation} from "react-i18next";
import NumberInput from "../../Components/Forms/NumberInput";
import {useChargePointConnectorsLazyQuery, useRatePlansQuery} from "../../generated/graphql";
import {useFieldArray, useForm} from "react-hook-form";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {gql, useMutation} from "@apollo/client";
import styled from "@emotion/styled";
import {ca} from "date-fns/locale";

type ConnectorsFormData = {
    type: string
    format: string
    current: string
    power: number
    rateplan: string
    id: string
    connectorId: string
}

type FormData = {
    connectors: ConnectorsFormData[]
}

const ConnectorIdLabel = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const ConnectorId = styled.div`
  text-align: center;
  margin-top: 5px;
`

const ButtonContainer = styled.div`
  margin-top: 15px;
  text-align: right;
`

const UPDATE_CONNECTORS_MUTATION = gql`
    mutation UpdateChargePointConnectors($chargePointId: ID!, $connectors: [ConnectorInput]) {
      updateChargePointConnectors(chargePointId: $chargePointId, connectors: $connectors) {
        id
      }
    }
`

interface Props {
    chargeStationId: string
    hideForm: () => void
}

export default ({ chargeStationId, hideForm }: Props) => {
    const { t, i18n } = useTranslation()
    const { data: ratePlans, loading, error } = useRatePlansQuery()
    const { register, handleSubmit, control, formState: { errors } } = useForm()
    const [ fetchConnectors, { data: connectors, loading: loadingConnectors }] = useChargePointConnectorsLazyQuery()
    const [ updateConnectors, { data, loading: updating, error: updateError }] = useMutation(UPDATE_CONNECTORS_MUTATION, {
        update: cache => {
            cache.evict({fieldName: "ChargePointConnectors"})
        }
    })

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: "connectors"
    })

    const ConnectorTypeOptions: SelectOption[] = [
        {
            label: t('ChademoConnectorLabel'),
            value: 'CHADEMO'
        },
        {
            label: t('GBTConnectorLabel'),
            value: 'GBT'
        },
        {
            label: t('CCS1ConnectorLabel'),
            value: 'CCSTYPE1'
        },
        {
            label: t('CCS2ConnectorLabel'),
            value: 'CCSTYPE2'
        },
        {
            label: t('TeslaConnectorLabel'),
            value: 'TESLA'
        },
        {
            label: t('Type1ConnectorLabel'),
            value: 'TYPE1J1772'
        },
        {
            label: t('Type2ConnectorLabel'),
            value: 'TYPE2'
        }
    ]

    const CableTypeOptions: SelectOption[] = [
        {
            label: t('CableFormatLabel'),
            value: 'CABLE'
        },
        {
            label: t('SocketTypeLabel'),
            value: 'SOCKET'
        }
    ]

    const CurrentTypeOptions: SelectOption[] = [
        {
            label: t('ACSinglePhase'),
            value: "AC1"
        },
        {
            label: t('ACThreePhase'),
            value: "AC3"
        },
        {
            label: t('DC'),
            value: "DC"
        }
    ]

    const RatePlanOptions = ratePlans?.ratePlans?.map(plan => ({
        label: `${plan?.name}`,
        value: `${plan?.id}`
    })) || []

    const onSubmit = (values: FormData) => {
        updateConnectors({
            variables: {
                chargePointId: chargeStationId,
                connectors: values.connectors.map(conn => ({
                    id: conn.ID,
                    current: conn.current,
                    format: conn.format,
                    power: parseInt(conn.power),
                    rateplan: conn.rateplan,
                    type: conn.type
                }))
            }
        }).then(() => {
            hideForm()
        })
    }

    const defaultNewOption = {
        type: "TYPE2",
        format: "SOCKET",
        current: "AC3",
        power: 11,
        rateplan: RatePlanOptions[0]?.value
    }

    useEffect(() => {
        fetchConnectors({
            variables: {
                chargepointId: chargeStationId
            }
        })
    }, [])

    useEffect(() => {
        if(!loadingConnectors && connectors?.chargePoint?.connectors?.length && connectors?.chargePoint?.connectors?.length > 0) {
            const conn = connectors?.chargePoint?.connectors?.map(connector => ({
                ID: connector?.id || null,
                connectorId: connector?.connectorId || "",
                type: connector?.type || "",
                format: connector?.format || "",
                current: connector?.current || "",
                power: connector?.power || 11,
                rateplan: connector?.ratePlan?.id || ""
            }))
            replace(conn)
        } else if(!loadingConnectors) {
            append(defaultNewOption)
        }
    }, [ connectors, loadingConnectors ])

    return (
        <div>
        <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => {
                return (
                    <Grid gutter={'small'} key={item.ID}>
                        <div>
                            <ConnectorIdLabel>{t('ConnectorID')}</ConnectorIdLabel>
                            <ConnectorId>{item.connectorId}</ConnectorId>
                        </div>
                        <div>
                            <input
                                type="hidden"
                                value={item.ID}
                                {...register(`connectors.${index}.id` as 'connectors.0.id')}
                            />
                            <SelectInput
                                options={ConnectorTypeOptions}
                                label={t('ConnectorTypeLabel')}
                                defaultValue={item.type}
                                {...register(`connectors.${index}.type` as 'connectors.0.type', {
                                    required: true
                                })}
                            />
                        </div>
                        <div>
                            <SelectInput
                                options={CableTypeOptions}
                                label={t('CableTypeLabel')}
                                defaultValue={item.format}
                                {...register(`connectors.${index}.format` as 'connectors.0.format', {
                                    required: true
                                })}
                            />
                        </div>
                        <div>
                            <SelectInput
                                options={CurrentTypeOptions}
                                label={t('CurrentTypeLabel')}
                                defaultValue={item.current}
                                {...register(`connectors.${index}.current` as 'connectors.0.current', {
                                    required: true
                                })}
                            />
                        </div>
                        <div>
                            <NumberInput
                                label={t('PowerLabel')}
                                defaultValue={item.power}
                                {...register(`connectors.${index}.power` as 'connectors.0.power', {
                                    required: true
                                })}
                            />
                        </div>
                        <div>
                            <SelectInput
                                options={RatePlanOptions}
                                label={t('RatePlan')}
                                defaultValue={item.rateplan}
                                {...register(`connectors.${index}.rateplan` as 'connectors.0.rateplan',{
                                    required: true
                                })}
                            />
                        </div>
                        <div className="uk-margin-small-left">
                            <Button
                                className="uk-button uk-button-primary uk-button-small"
                                style={{marginTop: "33px"}}
                                onClick={() => append(defaultNewOption)}
                            >
                                +
                            </Button>

                            {fields.length > 1 &&
                                <Button
                                    className="uk-button uk-button-secondary uk-button-small"
                                    style={{marginTop: "33px", marginLeft: "5px"}}
                                    onClick={() => remove(index)}
                                >
                                    -
                                </Button>
                            }
                        </div>
                    </Grid>
                )
            })}
            <ButtonContainer className={"is-fullwidth uk-flex uk-flex-row uk-flex-right"} key={'buttons'}>
                <Button onClick={() => hideForm()} disabled={updating}>{t('Cancel')}</Button>
                <PrimaryButton type='submit' disabled={updating} className="uk-margin-small-left">{t('Save')}</PrimaryButton>
            </ButtonContainer>
        </form>
        </div>
    )
}