import React, {ReactNode} from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Modal = styled.div`
  max-width: 800px;
  max-height: 450px;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 32px 0 rgba(71,74,79,0.29);
  background-color: #FFFFFF;
`

interface Props {
    children: ReactNode
}

export const CenterScreenModal = ({ children }: Props) => {
    return(
        <Container>
            <Modal>
                {children}
            </Modal>
        </Container>
    )
}
