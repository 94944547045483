import React, {useEffect} from 'react'
import {gql, useLazyQuery} from "@apollo/client";
import LoadingSpinner from "../../Components/App/LoadingSpinner";
import {useTranslation} from "react-i18next";
import Table from "../../Components/Table/Table";
import {Link} from "react-router-dom";
import routes from "../../Router/routes";
import {ClientForCardHoldersQueryResult, useClientForCardHoldersLazyQuery} from "../../generated/graphql";

interface Props {
    clientId: string
}

const FETCH_CARD_HOLDERS_FOR_CLIENT_QUERY = gql`
    query ClientForCardHolders($clientId: ID!) {
      client(clientId: $clientId) {
        cardHolders {
          id
          lastName
          firstName
          salutation
        }
      }
    }
`

export default ({clientId}: Props) => {
    const { t, i18n } = useTranslation()
    const [ fetchCardHolders, { data, loading }] = useClientForCardHoldersLazyQuery()

    useEffect(() => {
        fetchCardHolders({
            variables: {
                clientId
            }
        })
    }, [clientId])

    if(!clientId) return null

    const CardHoldersColumns = [
        {
            Header: t('FirstName'),
            accessor: 'firstName'
        },
        {
            Header: t('LastName'),
            accessor: 'lastName'
        },
        {
            Header: t('Actions'),
            accessor: 'actions'
        },
    ]

    const cardHolders = data?.client?.cardHolders?.map(holder => ({
        firstName: <Link to={`${routes.cardHolders.base}/${holder?.id}`} >{holder?.firstName}</Link>,
        lastName: holder?.lastName
    })) || []

    return (
        <>
            { loading && <LoadingSpinner /> }
            {!loading && data?.client?.cardHolders?.length === 0 && <p>{t('NoCardHoldersClient')}</p> }
            {!loading && data?.client?.cardHolders && data?.client?.cardHolders.length > 0 &&
                <Table columns={CardHoldersColumns} data={cardHolders} />
            }
        </>
    )
}