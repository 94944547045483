import React from "react";
import {gql, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {ClientChargeSessionsQueryHookResult, useClientChargeSessionsQuery} from "../../generated/graphql";
import LoadingSpinner from "../../Components/App/LoadingSpinner";
import Table from "../../Components/Table/Table";
import {intlFormat, parseISO} from "date-fns";
import {Link} from "react-router-dom";
import routes from "../../Router/routes";


interface Props {
    clientId: string
}

const CHARGE_SESSIONS_QUERY = gql`
    query ClientChargeSessions($clientId: ID!) {
      client(clientId: $clientId) {
        sessions {
          id
          started
          stopped
          status
          timeSpend
          transactionId
          chargeConnector
          chargePoint {
            id
            identifier
            location {
              address
              city
              name
              country
            }
          }
        }
      }
    }
`
export default ({ clientId }: Props) => {
    const { t, i18n } = useTranslation()
    const { data, loading } = useClientChargeSessionsQuery({
        variables: {
            clientId
        }
    })

    if(!clientId) return null

    const ChargeSessionsColumns = [
        {
            Header: t('Started'),
            accessor: 'started'
        },
        {
            Header: t('Stopped'),
            accessor: 'stopped'
        },
        {
            Header: t('Status'),
            accessor: 'status'
        },
        {
            Header: t('Location'),
            accessor: 'location'
        },
    ]

    const sessions = data?.client?.sessions?.map(session => {
        const started = <Link to={`${routes.transactions.base}/${session?.id}`}>
            {`${parseISO(session?.started).toLocaleDateString()} ${parseISO(session?.started).toLocaleTimeString()}`}
        </Link>;
        const stopped = session?.stopped ? `${parseISO(session?.stopped).toLocaleDateString()} ${parseISO(session?.stopped).toLocaleTimeString()}` : '';
        const location = <Link to={`${routes.chargeStations.base}/${session?.chargePoint?.id}`}>
            {`${session?.chargePoint?.location?.name}, ${session?.chargePoint?.location?.city}`}
        </Link>
        return {
            ...session,
            started,
            stopped,
            location
        }
    }) || []

    return (
        <>
            { loading && <LoadingSpinner /> }
            {!loading && sessions.length === 0 && <p>{t('NoChargeSessionsClient')}</p> }
            {!loading && sessions &&
                <Table data={sessions} columns={ChargeSessionsColumns} />
            }
        </>
    )
}