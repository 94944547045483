import React from 'react'
import {useTheme} from "@emotion/react";
import {RingLoader} from "react-spinners";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingSpinner = () => {
    const theme = useTheme()

    return (
        <Container>
            <RingLoader loading={true} color={theme.colors.yellow} />
        </Container>
    )
}

export default LoadingSpinner
