import React, {ReactNode} from 'react'
import styled from "@emotion/styled";
import PageTitle from "./PageTitle";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

interface PageHeaderProps {
    children?: ReactNode
    title: string
}

const PageHeader = ({ children, title, ...props }: PageHeaderProps) => {

    return (
        <Wrapper {...props}>
            <PageTitle>
                {title}
            </PageTitle>
            {children}
        </Wrapper>
    )
}

export default PageHeader
