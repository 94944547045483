import React, {useEffect, useState} from "react";
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useTheme} from "@emotion/react";
import LoadingSpinner from "../../Components/App/LoadingSpinner";
import PageTitle from "../../Components/Headings/PageTitle";
import LocationForm from "./Components/LocationForm";
import {GetLocationQueryHookResult, Location, useGetLocationQuery} from "../../generated/graphql";

type EditLocationScreenParams = {
    locationId: string
}

const EditLocationScreen = () => {
    const { locationId } = useParams() as EditLocationScreenParams
    const { t, i18n } = useTranslation()
    const theme = useTheme()

    const {loading: queryLoading, error, data }: GetLocationQueryHookResult = useGetLocationQuery({
        variables: {
            locationId
        },
        skip: locationId === undefined
    })

    const location = data?.location as unknown as Location

    const onSubmit = () => {

    }

    return (
        <AppLayout heading={
            <PageTitle>
                {data?.location?.name || t('Location')}
            </PageTitle>
        }>
            {queryLoading && <LoadingSpinner />}

            {!queryLoading && data &&
                <LocationForm onSubmit={onSubmit} existingData={location || null} />
            }

        </AppLayout>
    )
}

export default EditLocationScreen
