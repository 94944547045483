import React from 'react'
import AppLayout from "../../Layouts/AppLayout";
import {gql, useQuery} from "@apollo/client";
import PageHeader from "../../Components/Headings/PageHeader";
import {Card, CardBody, Grid, Section} from "uikit-react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import Label from "../../Components/Typography/Label";
import {formatIso, secondsToTime} from "../../util/dateTime";
import Duration from "../../Components/DateTime/Duration";
import {Link} from "react-router-dom";
import routes from "../../Router/routes";
import LoadingSpinner from "../../Components/App/LoadingSpinner";

const ChargeSessionQuery = gql`
    query ChargeSession($chargeSessionId: ID!) {
      chargeSession(chargeSessionId: $chargeSessionId) {
        id
        idTag
        meterStart
        meterStop
        started
        stopped
        status
        stoppedReason
        timeSpend
        transactionId
        type
        costs {
          currency
          total
          totalExclTax
          totalTax
          costItems {
            amount
            description
            price
            priceIncludesTax
            subTotal
            tax
            subTotalExclTax
            taxPercentage
            unit
          }
        }
        energyConsumed
        invoice
        invoiceStatus
        ratePlan {
          currency
          id
          name
          rates {
            description
            price
            priceIncludesTax
            taxPercentage
            unit
          }
        }
        chargePoint {
          id
          identifier
          location {
            id
            name
            city
            country
            address
            address1
            postalCode
            province
          }
          connectors {
            connectorId
            id
          }
        }
        chargeConnector
        chargeCard {
          cardNumber
          id
          type
          cardHolder {
            firstName
            lastName
            id
            client {
              id
              name
            }
          }
        }
      }
    }
`

const TransactionDetailsScreen = () => {
    const { t, i18n } = useTranslation()
    const { chargeSessionId } = useParams()
    const { loading, data } = useQuery(ChargeSessionQuery, {
        variables: {
            chargeSessionId
        },
        fetchPolicy: "no-cache"
    })
    console.log({data})

    const { chargeSession } = data || {}

    //@todo: determine payment method used
    //@todo: graph with meter values

    //@ts-ignore
    const usedConnector = chargeSession?.chargePoint?.connectors?.find(conn => conn.connectorId === chargeSession?.chargeConnector) || {}
console.log({usedConnector})
    return (
        <AppLayout>
            <PageHeader title={t('ChargeSession')} />
            {loading && <LoadingSpinner />}
            {!loading && chargeSession &&
            <Grid gutter='small'>
                <Section width='1-3'>
                    <Card size='small'>
                        <CardBody>
                            <div className='uk-flex uk-flex-row uk-flex-between'>
                                <div>
                                    <Label>{t('Session number')}</Label>
                                    <p>{chargeSession.transactionId}</p>

                                    <Label>{t('Status')}</Label>
                                    <p>{chargeSession.status}</p>

                                    <Label>{t('Started')}</Label>
                                    <p>{formatIso(chargeSession.started)}</p>

                                    <Label>{t('Stopped')}</Label>
                                    {chargeSession.stopped && <p>{formatIso(chargeSession.stopped)}</p>}
                                </div>
                                <div>
                                    <Label>Meter start</Label>
                                    <p>{chargeSession.meterStart / 1000}</p>

                                    <Label>Meter stop</Label>
                                    {chargeSession.meterStop && <p>{chargeSession.meterStop / 1000}</p>}

                                    <Label>Energy consumed</Label>
                                    <p>{chargeSession.energyConsumed / 1000} kWh</p>

                                    <Label>Duration</Label>
                                    {chargeSession.timeSpend && <p><Duration duration={secondsToTime(chargeSession.timeSpend)}/></p>}

                                    <Label>Stopped reason</Label>
                                    <p>{chargeSession.stoppedReason}</p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Section>

                <Section width='1-3'>
                    <Card size='small'>
                        <CardBody>
                            <div className='uk-flex uk-flex-row uk-flex-between'>
                                <div>
                                    <Label>{t('Payment method')}</Label>
                                    <p>RFiD</p>

                                    {chargeSession.chargeCard && <Label>Charge card</Label> }
                                    {chargeSession.chargeCard &&
                                        <p>
                                            <Link to={`${routes.cards.base}/${chargeSession.chargeCard.id}`}>
                                                {chargeSession.chargeCard.cardNumber}
                                            </Link>
                                        </p>
                                    }

                                    <Label>Customer</Label>
                                    {chargeSession.chargeCard?.cardHolder?.client && <p>
                                        <Link to={`${routes.customers.base}/${chargeSession.chargeCard?.cardHolder?.client.id}`}>
                                            {chargeSession.chargeCard?.cardHolder?.client?.name}
                                        </Link>
                                    </p>}
                                    {!chargeSession.chargeCard?.cardHolder?.client && <p>{t('Unknown')}</p>}

                                    <Label>Card holder</Label>
                                    {chargeSession.chargeCard?.cardHolder && <p>
                                        <Link to={`${routes.cardHolders.base}/${chargeSession.chargeCard?.cardHolder.id}`}>
                                            {chargeSession.chargeCard?.cardHolder.firstName} {chargeSession.chargeCard?.cardHolder.lastName}
                                        </Link>
                                    </p> }
                                    {!chargeSession.chargeCard?.cardHolder && <p>{t('Unknown')}</p>}
                                </div>
                                <div>
                                    <Label>{t('RatePlan')}</Label>
                                    <div>
                                        <p>
                                            {chargeSession?.ratePlan?.name}
                                        </p>
                                        {//@ts-ignore
                                         chargeSession.ratePlan?.rates?.map(rate => (
                                                <p>{chargeSession.ratePlan?.currency} {rate.price} / {rate.unit}</p>
                                        ))}
                                    </div>

                                    <Label>{t('Total')}</Label>
                                    {chargeSession?.costs?.currency && chargeSession?.costs?.total &&
                                        <p>
                                            {new Intl.NumberFormat(i18n.language,{
                                            style: "currency",
                                            currency: chargeSession?.costs?.currency
                                            }).format(chargeSession?.costs?.total)}
                                        </p>
                                    }

                                    <Label>{t('InvoiceStatus')}</Label>
                                    {!chargeSession?.invoiceStatus &&
                                        <p>{t('InvoiceStatusNot')}</p>
                                    }

                                    <Label>{t('Invoice')}</Label>
                                    <p></p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Section>

                <Section width='1-3'>
                    <Card size='small'>
                        <CardBody>
                            <div className='uk-flex uk-flex-row uk-flex-between'>
                                <div>
                                    <Label>{t('ChargeStation')}</Label>
                                    <p><Link to={`${routes.chargeStations.base}/${chargeSession.chargePoint?.id}`}>{chargeSession.chargePoint?.identifier}</Link></p>

                                    <Label>{t('Connector')}</Label>
                                    <p>{chargeSession.chargeConnector}</p>
                                </div>
                                <div>
                                    <Label>{t('LocationLabel')}</Label>
                                    <p>
                                        <Link to={`${routes.locations.base}/${chargeSession.chargePoint?.location?.id}`}>
                                            {chargeSession.chargePoint?.location?.name}
                                        </Link>
                                    </p>

                                    <Label>{t('Address')}</Label>
                                    <p>
                                        {chargeSession.chargePoint?.location?.address} <br />
                                        {chargeSession.chargePoint?.location?.address1 && <>{chargeSession.chargePoint?.location?.address1}<br /></>}
                                        {chargeSession.chargePoint?.location?.postalCode} {' '} {chargeSession.chargePoint?.location?.city} <br />
                                        {chargeSession.chargePoint?.location?.province && <>{chargeSession.chargePoint?.location?.province}<br /></>}
                                        {chargeSession.chargePoint?.location?.country && <>{chargeSession.chargePoint?.location?.country}<br /></>}
                                    </p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Section>
            </Grid>
            }
        </AppLayout>
    )
}

export default TransactionDetailsScreen
