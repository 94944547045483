import React from 'react'
import styled from "@emotion/styled";
import pattern from "../../media/patterns/pattern_01.svg";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 100%;
  height: 100%;
`

const LeftImage = styled.div`
  background-image: url(${pattern});
  background-size: cover;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  height: 100%;
`

const SignupWindow = ({children}: { children: JSX.Element}) => {

    return (
        <Container>
            <LeftImage />
            <RightContainer>
                {children}
            </RightContainer>
        </Container>
    )
}

export default SignupWindow
