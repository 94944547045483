import React, {useEffect, useState} from 'react';
import Router from "./Router/Router";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, makeVar, useReactiveVar} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";

export const userToken = makeVar<null | string >(localStorage.getItem("userToken"))
export const userId = makeVar<null | string >(localStorage.getItem("userId"))
export const customerId = makeVar<null | string >(localStorage.getItem("customerId"))

function App() {
  const [ authToken, setAuthToken ] = useState<null | string>(null)
  const token = useReactiveVar(userToken)
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: authToken ? `Bearer ${authToken}` : undefined,
      }
    }
  })

  const apiClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  useEffect(() => {
    if(token && token !== authToken) setAuthToken(token)
  }, [authToken, token])

  return (
      <ApolloProvider client={apiClient}>
        <Router />
      </ApolloProvider>
  );
}

export default App;
