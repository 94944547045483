import React, {useState} from 'react'
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";


const LogoContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const StripeLogo = styled.img`
  width: 100px;
`

const PaymentButton = styled.div`
  margin: 15px auto 0;
  text-align: right;
`

const OnboardingPaymentForm = () => {
    const { t, i18n } = useTranslation()
    const stripe = useStripe();
    const elements = useElements();
    const [ loading, setLoading ] = useState(false)

    //@ts-ignore
    const saveWithStripe = async (event) => {
        event.preventDefault();
        setLoading(true)

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        //@todo: url from config based on env
        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_CSMS_URL}/onboarding/complete`
            }
        })

        //@todo: error handling
        console.error(error)
    }

    return (
        <form onSubmit={saveWithStripe}>
            <PaymentElement />

            <LogoContainer>
                <StripeLogo src='https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg' alt='Stripe logo'/>
            </LogoContainer>

            <PaymentButton>
                <PrimaryButton disabled={loading || !stripe} type='submit'>
                    {t('Next')}
                </PrimaryButton>
            </PaymentButton>
        </form>
    )
}

export default OnboardingPaymentForm
