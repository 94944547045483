import React, {useEffect, useMemo} from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import {useTranslation} from "react-i18next";
import {Card, CardBody, Grid, Section} from "uikit-react";
import {gql, useLazyQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import LoadingSpinner from "../../Components/App/LoadingSpinner";
import Label from "../../Components/Typography/Label";
import Table from "../../Components/Table/Table";

const GET_RATEPLAN_QUERY = gql`
    query RatePlan($ratePlanId: ID!) {
      ratePlan(ratePlanId: $ratePlanId) {
        currency
        id
        name
        rates {
          description
          price
          unit
        }
      }
    }
`

export default () => {
    const { t, i18n } = useTranslation()
    const { planId } = useParams()
    const [ fetchRateplan, { data, loading }] = useLazyQuery(GET_RATEPLAN_QUERY)

    useEffect(() => {
        if(planId) {
            fetchRateplan({
                variables: {
                    ratePlanId: planId
                }
            })
        }
    }, [planId])

    if(!planId) return null

    const ratePlan = data?.ratePlan
console.log(ratePlan)
    const ratesTableColumns = useMemo(() => {
        return [
            {
                Header: t('Description'),
                accessor: 'description'
            },
            {
                Header: t('Price'),
                accessor: 'price'
            },
            {
                Header: t('Unit'),
                accessor: 'unit'
            }
        ]
    }, [t])
    console.log(ratesTableColumns)

    return (
        <AppLayout heading={<PageHeader title={t('RatePlanDetailsTitle')} />}>
            <Grid gutter='small'>
                <Section width='expand'>
                    {loading && <LoadingSpinner />}
                    {!loading && ratePlan &&
                        <Card>
                            <CardBody>
                                <Grid>
                                    <Section width='1-2'>
                                        <Label>Name</Label>
                                        <p>{ratePlan.name}</p>
                                    </Section>
                                    <Section width='1-2'>
                                        <Label>Currency</Label>
                                        <p>{ratePlan.currency}</p>
                                    </Section>
                                </Grid>

                                {ratePlan?.rates?.length > 0 &&
                                    <>
                                        <h4>Rates</h4>
                                        <Table data={ratePlan.rates} columns={ratesTableColumns} size='fullwidth' hoverable={true} />
                                    </>
                                }
                            </CardBody>
                        </Card>
                    }
                </Section>
            </Grid>
        </AppLayout>
    )
}