import React, {ReactNode} from 'react'
import styled from "@emotion/styled";

const StyledH2 = styled.h2`
  font-size: 1.1em;
  text-transform: capitalize;
`

interface Props {
    children: ReactNode
}

const CardTitle = ({ children, ...props }: Props) => {

    return (
        <StyledH2 className='uk-heading-small uk-heading-divider' {...props} >{children}</StyledH2>
    )

}

export default CardTitle
