import React, {InputHTMLAttributes} from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  
  &:first-child {
    margin-top: 0;
  }
`

const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
`

const StyledInput = styled('input', {
    shouldForwardProp: (prop) => prop !== 'hasLabel'
})<{hasLabel?: boolean}>`
    margin-top: ${({ hasLabel }) => hasLabel ? 0 : '10px'};
`

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
}

const NumberInput = React.forwardRef<HTMLInputElement, Props>(({label, ...props}, ref) => {
    return (
        <Wrapper>
            {label && <Label>{label}</Label>}
            <StyledInput className={'uk-input'} type='number' min="0" step="any" {...props} ref={ref} hasLabel={Boolean(label)} />
        </Wrapper>
    )
})

export default NumberInput
