import colors from "./colors";

const baseFontSize = 16

export const pxToRem = (size: number) => size / baseFontSize

const headingBase = {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    color: colors.grayDark
}

const typography = {
    H1: {
        ...headingBase,
        fontSize: `${pxToRem(100)}rem`,
        lineHeight: `${pxToRem(110)}rem`,
    },
    H2: {
        ...headingBase,
        fontSize: `${pxToRem(80)}rem`,
        lineHeight: `${pxToRem(90)}rem`,
    },
    H3: {
        ...headingBase,
        fontSize: `${pxToRem(60)}rem`,
        lineHeight: `${pxToRem(70)}rem`,
    },
    H4: {
        ...headingBase,
        fontSize: `${pxToRem(50)}rem`,
        lineHeight: `${pxToRem(60)}rem`,
    },
    H5: {
        ...headingBase,
        fontSize: `${pxToRem(40)}rem`,
        lineHeight: `${pxToRem(50)}rem`,
    },
    H6: {
        ...headingBase,
        fontSize: `${pxToRem(30)}rem`,
        lineHeight: `${pxToRem(40)}rem`,
    },
    p: {
        fontFamily: 'Source Sans Pro',
        fontWeight: 300,
        fontSize: `${pxToRem(18)}rem`,
        lineHeight: `${pxToRem(28)}rem`,
        color: colors.grayDark
    }
}

export default typography
