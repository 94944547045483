import React from "react";
import styled from "@emotion/styled";
import logo from '../../media/logo.svg'

const Image = styled.img`
  width: 150px;
`

const Logo = ({...props}) => {
    return (
        <Image src={logo} {...props} />
    )
}

export default Logo
