import React from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {useNavigate} from "react-router";
import routes from "../../Router/routes";
import {gql, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Card, CardBody} from "uikit-react";
import Table from "../../Components/Table/Table";

const getCardsQuery = gql`
    query GetChargeCards {
        getChargeCards {
            id
            idTag
            cardNumber
            active
            type
            customer {
                name
            }
            cardHolder {
                firstName
                lastName
            }
        }
    }
`

const CardsScreen = () => {
    const navigate = useNavigate()
    const { loading, data } = useQuery(getCardsQuery)
    const { t, i18n } = useTranslation()

    const columns = [
        {
            Header: t('ID'),
            accessor: 'idTag'
        },
        {
            Header: t('Card number'),
            accessor: 'cardNumber'
        },
        {
            Header: t('Active'),
            accessor: 'active'
        },
        {
            Header: t('Type'),
            accessor: 'type'
        },
        {
            Header: t('Customer'),
            accessor: 'customer'
        },
        {
            Header: t('Card holder'),
            accessor: 'cardHolder'
        }
    ]

    const {getChargeCards} = data || {}

    //@ts-ignore
    const cards = getChargeCards?.map(card => {
        const holder = []
        holder.push(card?.cardHolder?.firstName)
        holder.push(card?.cardHolder?.lastName)

        return {
            idTag: <Link to={`${routes.cards.base}/${card.id}`}>{card.idTag}</Link>,
            cardNumber: card.cardNumber,
            active: card.active ? t('Active') : t('Disabled'),
            type: card.type,
            customer: card.customer?.name || '',
            cardHolder: holder.filter(h => h !== undefined).join(', ') || ''
        }
    }) || []

    return (
        <AppLayout heading={
            <PageHeader title={'Cards'}>
                <PrimaryButton onClick={() => navigate(`${routes.cards.base}/${routes.cards.add}`)}>
                    Add card
                </PrimaryButton>
            </PageHeader>
        }>

            <Card>
                <CardBody>
                    {cards &&
                        <Table columns={columns} data={cards} />
                    }
                </CardBody>
            </Card>
        </AppLayout>
    )
}

export default CardsScreen
