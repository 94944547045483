import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faHome, faMap, faBuilding, faCreditCard, faReceipt, faChargingStation, faCog, faIgloo, faCoins } from '@fortawesome/free-solid-svg-icons'
import {ComponentProps} from "react";

type IconProps = Omit<ComponentProps<typeof FontAwesomeIcon>, "icon">

export const CheckMarkIcon = (props: IconProps) => (<FontAwesomeIcon {...props} icon={faCheck} />)
export const HomeIcon = (props: IconProps) => (<FontAwesomeIcon {...props} icon={faHome} />)
export const MapIcon = (props: IconProps) => (<FontAwesomeIcon {...props} icon={faMap} />)
export const BuildingIcon = (props: IconProps) => (<FontAwesomeIcon {...props} icon={faBuilding} />)
export const CreditCardIcon = (props: IconProps) => (<FontAwesomeIcon {...props} icon={faCreditCard} />)
export const ReceiptIcon = (props: IconProps) => (<FontAwesomeIcon {...props} icon={faReceipt} />)
export const ChargingStationIcon = (props: IconProps) => (<FontAwesomeIcon {...props} icon={faChargingStation} />)
export const CogIcon = (props: IconProps) => (<FontAwesomeIcon {...props} icon={faCog} />)
export const IglooIcon = (props: IconProps) => (<FontAwesomeIcon {...props} icon={faIgloo} />)
export const CoinsIcon = (props: IconProps) => (<FontAwesomeIcon {...props } icon={faCoins} />)
