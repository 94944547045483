import React from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import {useTranslation} from "react-i18next";
import {Card, CardBody} from "uikit-react";

export default () => {
    const { t, i18n } = useTranslation()

    return (
        <AppLayout heading={<PageHeader title={t('CardHolders')} />}>

            <Card>
                <CardBody>
                    
                </CardBody>
            </Card>

        </AppLayout>
    )
}