import React, {useEffect} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import {useChargePointConnectorsLazyQuery} from "../../generated/graphql";
import {useTranslation} from "react-i18next";
import Table from "../../Components/Table/Table";
import LoadingSpinner from "../../Components/App/LoadingSpinner";

interface Props {
    chargeStationId: string
}

export const GET_CHARGE_STATION_CONNECTORS_QUERY = gql`
    query ChargePointConnectors($chargepointId: ID!) {
        chargePoint(chargepointId: $chargepointId) {
            connectors {
              connectorId
              current
              errorCode
              format
              id
              info
              power
              status
              type
              updated
              ratePlan {
                id
                name
              }
            }
      }
    }
`

export default ({ chargeStationId }: Props) => {
    const { t, i18n } = useTranslation()
    const [ fetchConnectors, { data, loading }] = useLazyQuery(GET_CHARGE_STATION_CONNECTORS_QUERY, {
        pollInterval: 5000,
        fetchPolicy: "no-cache"
    })

    if(!chargeStationId) return null

    useEffect(() => {
        fetchConnectors({
            variables: {
                chargepointId: chargeStationId
            }
        })
    }, [chargeStationId])

    const CONNECTORS_TABLE_COLUMNS = [
        {
            Header: t('ConnectorId'),
            accessor: 'connectorId'
        },
        {
            Header: t('ConnectorTypeLabel'),
            accessor: 'type'
        },
        {
            Header: t('CableTypeLabel'),
            accessor: 'format'
        },
        {
            Header: t('CurrentTypeLabel'),
            accessor: 'current'
        },
        {
            Header: t('PowerLabel'),
            accessor: 'power'
        },
        {
            Header: t('RatePlanLabel'),
            accessor: 'ratePlan'
        },
        {
            Header: t('ConnectorStatusLabel'),
            accessor: 'status'
        },

    ]

    //@ts-ignore
    const connectors = data?.chargePoint?.connectors?.map(connector => ({
        ...connector,
        ratePlan: connector.ratePlan?.name || ''
    })) || []

    console.log({connectors})
    return (
        <>{loading && <LoadingSpinner />}
            <Table data={connectors} columns={CONNECTORS_TABLE_COLUMNS} />
        </>
    )
}