import React, {InputHTMLAttributes} from 'react'
import styled from "@emotion/styled";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  
  &:first-child {
    margin-top: 0;
  }
`

const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
`

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
    label?: string
    rows?: number
}

const TextareaInput = React.forwardRef<HTMLTextAreaElement, Props>(({ label, rows, ...props }: Props, ref) => {

    return (
        <Wrapper>
            {label && <Label>{label}</Label>}
            <textarea {...props}  className='uk-textarea' ref={ref} rows={rows} />
        </Wrapper>
    )
})

export default TextareaInput
