import React, {useEffect, useState} from "react";
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import {useTranslation} from "react-i18next";
import TextInput from "../../Components/Forms/TextInput";
import SelectInput, {SelectOption} from "../../Components/Forms/SelectInput";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import styled from "@emotion/styled";
import {useForm} from "react-hook-form";
import {gql, useMutation} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import routes from "../../Router/routes";
import {Card, CardBody, Grid, Section} from 'uikit-react'
import TextareaInput from "../../Components/Forms/TextareaInput";
import CheckboxInput from "../../Components/Forms/CheckBoxInput";
import Label from "../../Components/Typography/Label";

export const LocationAccessOptions = [
    {
        value: 'PUBLIC',
        label: 'Public'
    },
    {
        value: 'PRIVATE',
        label: 'Private'
    },
    {
        value: 'GUEST',
        label: 'Guests only'
    }
] as unknown as [SelectOption]

export const LocationLocatedAtOptions = [
    {
        value: 'ALONG_MOTORWAY',
        label: 'Along motorway'
    },
    {
        value: 'PARKING_GARAGE',
        label: 'In parking garage'
    },
    {
        value: 'PARKING_LOT',
        label: 'In parking lot'
    },
    {
        value: 'ON_DRIVEWAY',
        label: 'In driveway'
    },
    {
        value: 'ON_STREET',
        label: 'In public space'
    },
    {
        value: 'UNDERGROUND_GARAGE',
        label: 'Underground parking garage'
    },
    {
        value: 'OTHER',
        label: 'Other'
    }
] as unknown as [SelectOption]

const SaveLocationMutation = gql`
    mutation CreateLocation(
        $name: String
        $address: String
        $address1: String
        $postalCode: String
        $city: String
        $country: String
        $access: LocationAccess!
        $restrictions: [ParkingRestrictions]
        $directions: String
        $location: Located
        $directories: Boolean!
    ) {
        createLocation(input: {
            name: $name
            address: $address
            address1: $address1
            postalCode: $postalCode
            city: $city
            country: $country
            access: $access
            restrictions: $restrictions
            directions: $directions
            located: $location
            directories: $directories
        }) {
            id
        }
    }
`

const ButtonContainer = styled.div`
  margin-top: 15px;
  text-align: right;
`

//@todo: input validation
//@todo: required fields mark
const CreateLocationScreen = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [ loading, setLoading ]= useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [ createLocation, { data: createLocationResult }] = useMutation(SaveLocationMutation)

    //@ts-ignore
    const saveLocation = async (data) => {
        setLoading(true)
        try {
            const parkingRestrictions = []

            for(const key of Object.keys(data)) {
                if(key.startsWith('parking_') && data[key] === true) {
                    parkingRestrictions.push(key.replace('parking_', '').toUpperCase())
                }
            }

            await createLocation({
                variables: {
                    ...data,
                    directories: false, //Default false right now as we don't have these integrations
                    restrictions: parkingRestrictions.length > 0 ? parkingRestrictions : undefined
                }
            })
            setLoading(false)
        } catch(err) {
            console.error(err)
            setLoading(false)
        }
    }

    useEffect(() => {
        if(createLocationResult?.createLocation?.id) {
            navigate(`${routes.locations.base}/${createLocationResult?.createLocation?.id}`)
        }
    }, [createLocationResult])

    return (
        <AppLayout heading={<PageHeader title={t('New location')} />}>
            <form onSubmit={handleSubmit(saveLocation)}>
                <Grid gutter='small'>
                    <Section width='expand'>
                    <Card>
                        <CardBody>
                            <TextInput label={t('Name')} {...register('name', { required: false })}/>
                            <TextInput label={t('Address')} {...register('address', { required: true })}/>
                            <TextInput {...register('address1', { required: false })}/>

                            <Grid gutter='small'>
                                <Section width='1-3@m'><TextInput label={t('Postal code')} {...register('postalCode', { required: false })}/></Section>
                                <Section width='expand'><TextInput label={t('City')} {...register('city', { required: true })}/></Section>
                            </Grid>

                            <TextInput label={t('Province / state')} {...register('province', { required: false })}/>
                            <ButtonContainer>
                                <PrimaryButton type={'submit'}>
                                    {t('Save')}
                                </PrimaryButton>
                            </ButtonContainer>
                        </CardBody>
                    </Card>
                    </Section>
                    {/*<Section width='1-3@m'>*/}
                    {/*<Card>*/}
                    {/*    <CardBody>*/}
                    {/*        Map*/}
                    {/*    </CardBody>*/}
                    {/*</Card>*/}
                    {/*</Section>*/}
                </Grid>

                <Grid gutter='small'>
                    <Section width='expand'>
                        <Card>
                            <CardBody>
                                <Grid gutter='small'>
                                    <Section width='1-2'>
                                        <TextareaInput label='Directions' {...register('directions', { required: false })} rows={7} />
                                    </Section>
                                    <Section width='1-2'>
                                        <SelectInput options={LocationAccessOptions} defaultValue={'PUBLIC'} label={'Access'} {...register('access', { required: true })} />
                                        <SelectInput options={LocationLocatedAtOptions} defaultValue={'PUBLIC'} label={'Location'} {...register('location', { required: true })} />
                                        <Label>Parking restrictions</Label>
                                        <Grid gutter='small'>
                                            <Section width='auto'><CheckboxInput {...register('parking_ev_only')} label='EV Only' /></Section>
                                            <Section width='auto'><CheckboxInput {...register('parking_plugged')} label='While plugged-in' /></Section>
                                            <Section width='auto'><CheckboxInput {...register('parking_disabled')} label='Reserved for disabled persons' /></Section>
                                            <Section width='auto'><CheckboxInput {...register('parking_customers')} label='For customers only' /></Section>
                                            <Section width='auto'><CheckboxInput {...register('parking_motorcycles')} label='Suitable for motorcycles' /></Section>
                                            <Section width='auto'><CheckboxInput {...register('parking_car_share')} label='Reserved for carsharing vehicles' /></Section>
                                        </Grid>
                                    </Section>
                                </Grid>
                            </CardBody>
                        </Card>
                    </Section>
                </Grid>
            </form>
        </AppLayout>
    )
}

export default CreateLocationScreen
