export const currencies = [
    'AUD',
    'CAD',
    'CHF',
    'CZK',
    'EUR',
    'DKK',
    'GBP',
    'GEL',
    'HUF',
    'HRK',
    'ISK',
    'MDL',
    'MKD',
    'NOK',
    'PLN',
    'SEK',
    'USD',
].sort()
