import React from 'react'
import FullScreenLayout from "../../Layouts/FullScreenLayout";
import {OnboardingModal, OnboardingWrapper} from "./CustomerOnboardingScreen";
import Logo from "../../Components/App/Logo";
import {useNavigate} from "react-router-dom";
import {Elements} from "@stripe/react-stripe-js";
import PaymentStatus from "./PaymentStatus";
import {loadStripe} from "@stripe/stripe-js";
import styled from "@emotion/styled";

const stripePromise = loadStripe('pk_test_51HD2TdJMW1VoM89zL0PxGmQnstbFCVtq0lucSbATz3Scg865O2c0gBYyYSmWxoEr74Lifuc4ZOIse2iZUYb2Jd7E00Ow6iaBlb');

const Loader = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CompleteOnboardingScreen = () => {
    const navigate = useNavigate()

    return (
        <FullScreenLayout>
            <OnboardingWrapper>
                <OnboardingModal>
                    <Logo />
                    <Elements stripe={stripePromise}>
                        <Loader>
                            <PaymentStatus />
                        </Loader>
                    </Elements>
                </OnboardingModal>
            </OnboardingWrapper>
        </FullScreenLayout>
    )
}

export default CompleteOnboardingScreen
