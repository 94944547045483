import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import routes from "./routes";
import {useReactiveVar} from "@apollo/client";
import {customerId, userToken} from "../App";

const RequireAuth = ({ children }: { children: JSX.Element}) => {
    const jwt = useReactiveVar(userToken)
    const customer = useReactiveVar(customerId)
    const location = useLocation()

    //@todo: verification
    if(!jwt) {
        return <Navigate to={routes.signin} state={{ from: location }} />
    }

    if(!customer) {
        return <Navigate to={`${routes.onboarding.base}/${routes.onboarding.customer}`} />
    }

    return children
}

export default RequireAuth
