import React, {ReactNode} from 'react'
import styled from "@emotion/styled";

const StyledLabel = styled.label`
  font-size: 1rem;
  font-weight: 600;
  display: block;
`

interface LabelProps {
    children: ReactNode
}

const Label = ({ children }: LabelProps) => {
    return (
        <StyledLabel>{children}</StyledLabel>
    )
}

export default Label
