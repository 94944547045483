import React from "react";
import AppLayout from "../../Layouts/AppLayout";
import {gql, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {useTheme} from "@emotion/react";
import PageTitle from "../../Components/Headings/PageTitle";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import Map from '../../Components/Map/Map'
import {Card, CardBody, Grid, Section} from "uikit-react";
import Label from "../../Components/Typography/Label";
import CardTitle from "../../Components/Typography/CardTitle";
import Table from "../../Components/Table/Table";
import {Link} from "react-router-dom";
import routes from "../../Router/routes";
import LoadingSpinner from "../../Components/App/LoadingSpinner";
import {parseISO} from "date-fns";

const StyledMap = styled(Map)`
  height: 250px;
`

export const LocationQuery = gql`
    query GetLocation($locationId: ID!) {
        location(locationId: $locationId) {
            id
            name
            address
            address1
            postalCode
            province
            city
            country
            access
            restrictions
            directions
            located
            geoLocation
            chargePoints {
              id
              identifier
              lastSeen
              status
            }
        }
    }
`

const chargePointColumns = [
    {
        Header: 'ID',
        accessor: 'identifier'
    },
    {
        Header: 'Status',
        accessor: 'status'
    },
    {
        Header: 'Last seen',
        accessor: 'lastSeen'
    },
]

const LocationDetailsScreen = () => {
    const { locationId } = useParams()
    const { t, i18n } = useTranslation()
    const theme = useTheme()

    const {loading: queryLoading, error, data } = useQuery(LocationQuery, {
        variables: {
            locationId
        }
    })

    const mapOptions = {
        interactive: true
    }

    const {location} = data || {}

    //@ts-ignore
    const chargeStations = location?.chargePoints?.map(station => ({
        identifier: <Link to={`${routes.chargeStations.base}/${station.id}`}>{station.identifier}</Link>,
        status: station.status,
        lastSeen: `${parseISO(station.lastSeen).toLocaleDateString()} ${parseISO(station.lastSeen).toLocaleTimeString()}`
    })) || []

    return (
        <AppLayout heading={
            <PageTitle>
                {data?.location?.name || t('Location')}
            </PageTitle>
        }>
            {queryLoading && <LoadingSpinner />}

            {data &&
            <Grid>
                <Section width='2-3'>
                    <Card>
                        <CardBody>
                            <Grid size='small'>
                                <Section width='1-2'>
                                    <Label>Name</Label>
                                    <p>{location.name}</p>

                                    <Label>Address</Label>
                                    <p>{location.address}</p>
                                    <p>{location.address1}</p>

                                    <Label>Postal code</Label>
                                    <p>{location.postalCode}</p>
                                    <Label>City</Label>
                                    <p>{location.city}</p>

                                    <Label>Province / state</Label>
                                    <p>{location.province}</p>

                                    <Label>Country</Label>
                                    <p>{location.country}</p>
                                </Section>
                                <Section width='1-2'>
                                    <Label>Access</Label>
                                    <p>{location.access}</p>

                                    <Label>Location</Label>
                                    <p>{location.located}</p>

                                    <Label>Parking restrictions</Label>
                                    <p>{location.restrictions && location.restrictions.length > 0 ? location.restrictions?.join(',') : 'None'}</p>

                                    <Label>Directions</Label>
                                    <p>{location.directions || 'None'}</p>
                                </Section>
                            </Grid>
                        </CardBody>
                    </Card>
                </Section>

                {location?.geoLocation &&
                    <Section width='1-3'>
                        <Card size='small'>
                            <CardBody>
                                <StyledMap
                                    lat={location?.geoLocation?.coordinates?.[1]}
                                    long={location?.geoLocation?.coordinates?.[0]}
                                    zoom={12}
                                    options={mapOptions}
                                    markers={[{
                                        lat: location?.geoLocation?.coordinates?.[1],
                                        long: location?.geoLocation?.coordinates?.[0]
                                    }]}
                                />
                            </CardBody>
                        </Card>
                    </Section>
                }
            </Grid>
            }

            {data &&
                <Grid size='small'>
                    <Section width='expand'>
                        <Card>
                            <CardBody>
                                <CardTitle>Charge stations</CardTitle>
                                {location && location.chargePoints && location.chargePoints.length > 0 &&
                                    <Table columns={chargePointColumns} data={chargeStations} />
                                }
                                {location && (!location.chargePoints || location.chargePoints.length === 0) &&
                                    <p>There are no charge stations installed at this location.</p>
                                }
                            </CardBody>
                        </Card>
                    </Section>
                </Grid>
            }

        </AppLayout>
    )
}

export default LocationDetailsScreen
