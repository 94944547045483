import React, {ReactNode} from 'react'
import styled from "@emotion/styled";
import {Link, useMatch, useResolvedPath} from "react-router-dom";
import {pxToRem} from "../../theme/typograhpy";

const Wrapper = styled.div`
  margin-top: 30px;
  
  &:first-of-type {
    margin-top: 0;
  }
`

const StyledLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'active'
})<{ active?: boolean }>`
  font-size: ${pxToRem(20)}rem;
  color: ${({ active, theme }) => active ? theme.colors.yellow : theme.colors.grayLight};
  text-decoration: none;
`

interface SideMenuItemProps {
    to: string
    label: string
    icon: ReactNode
}

const SideMenuItem = ({ icon, label, to, ...props }: SideMenuItemProps) => {
    const resolved = useResolvedPath(to)
    const active = Boolean(useMatch({ path: resolved.pathname, end: true }))

    return (
        <Wrapper {...props}>
            <StyledLink to={to} active={active}>
                {icon}
            </StyledLink>
        </Wrapper>
    )
}

export default SideMenuItem
