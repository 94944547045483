import React, {useMemo} from 'react'
import AppLayout from "../../Layouts/AppLayout";
import PageHeader from "../../Components/Headings/PageHeader";
import routes from "../../Router/routes";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import LoadingSpinner from "../../Components/App/LoadingSpinner";
import {Card, CardBody} from "uikit-react";
import Table from "../../Components/Table/Table";
import {useRatePlansQuery} from "../../generated/graphql";

export const GET_RATEPLANS_QUERY = gql`
    query RatePlans {
      ratePlans {
        currency
        id
        name
      }
    }
`

const RatePlanScreen = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const { data, loading, error } = useRatePlansQuery()

    console.log({plans: data})

    const columns = useMemo(()=> [
        {
            Header: t('Name'),
            accessor: 'name'
        },
        {
            Header: t('Currency'),
            accessor: 'currency'
        },
        {
            Header: 'Actions',
            accessor: 'actions'
        }
    ], [t])

    const mappedRatePlans = data?.ratePlans?.map(plan => ({
        name: <Link to={`${routes.rateplan.base}/${plan?.id}`}>{plan?.name}</Link>,
        currency: plan?.currency,
    })) || []

    return (
        <AppLayout heading={
            <PageHeader title={'Rate plan'}>
                <PrimaryButton onClick={() => navigate(`${routes.rateplan.base}/${routes.rateplan.create}`)}>
                    {t('Add rate plan')}
                </PrimaryButton>
            </PageHeader>
        }>
            {loading && <LoadingSpinner />}
            {!loading &&
                <Card>
                    <CardBody>
                        <Table columns={columns} data={mappedRatePlans} size='fullwidth' hoverable={true}/>
                        {mappedRatePlans.length < 1 &&
                            <>
                                {t('NoRatePlans')}
                            </>
                        }
                    </CardBody>
                </Card>
            }
        </AppLayout>
    )
}

export default RatePlanScreen
